import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Estilos com styled-components para web
const Container = styled.div`
  margin: 30px 0;
`;

const Header = styled.div`
  margin-left: 20px;
`;

const Title = styled.h2`
  font-size: 23px;
  font-weight: bold;
`;

const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const SuggestionList = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
`;

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  min-width: 300px;
`;

const SuggestionImage = styled.img`
  width: 300px;
  height: 150px;
  border-radius: 5px;
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  cursor: pointer;
  z-index: 10;
  padding: 10px;
  font-size: 24px;
`;

const LeftArrow = styled(Arrow)`
  left: 10px;
`;

const RightArrow = styled(Arrow)`
  right: 10px;
`;

export default function Suggestions() {
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const suggestionListRef = useRef(null);
  
  useEffect(() => {
    async function loadSuggestions() {
      try {
        const response = await fetch('https://vidapet.tech/MARKET/API/api_loja.php'); // URL real da API
        if (!response.ok) {
          throw new Error('Erro na requisição: ' + response.statusText);
        }
        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setError('Erro ao carregar dados.');
      }
    }

    loadSuggestions();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    }, 3000); // Alterar a cada 3 segundos
    
    return () => clearInterval(intervalId);
  }, [suggestions.length]);

  useEffect(() => {
    if (suggestionListRef.current) {
      suggestionListRef.current.style.transform = `translateX(-${currentIndex * 315}px)`;
    }
  }, [currentIndex]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + suggestions.length) % suggestions.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
  };

  return (
    <Container>
      <Header>
        <Title>Sugestões de Lojas</Title>
      </Header>
      <CarouselContainer>
        <LeftArrow onClick={handlePrevClick}>&lt;</LeftArrow>
        <SuggestionList ref={suggestionListRef}>
          {error ? (
            <p>{error}</p>
          ) : (
            suggestions.map(suggestion => (
              <Item to={`/lojas/${suggestion.id}`} key={suggestion.id}>
                <SuggestionImage
                  src={`https://vidapet.tech/MARKET/${suggestion.foto}`} // URL da foto
                  alt={suggestion.name_loja} // Usar o nome da loja como texto alternativo
                />
              </Item>
            ))
          )}
        </SuggestionList>
        <RightArrow onClick={handleNextClick}>&gt;</RightArrow>
      </CarouselContainer>
    </Container>
  );
}
