import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { IoMdCloseCircle } from 'react-icons/io';

const AdoptMe = ({ pet, onClose }) => {
  const [reason, setReason] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // Fetch states from IBGE API
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome');
        console.log('Estados carregados:', response.data);  // Log estados
        setStates(response.data);
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
      }
    };
    fetchStates();
  }, []);

  // Fetch cities from IBGE API
  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setState(selectedState);
    setCity(''); // Limpar cidade ao mudar estado

    console.log('Estado selecionado:', selectedState);  // Log estado selecionado

    try {
      const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState}/municipios`);
      console.log('Cidades carregadas:', response.data);  // Log cidades carregadas
      setCities(response.data);
    } catch (error) {
      console.error('Erro ao carregar cidades:', error);
    }
  };

  const handleSubmit = async () => {
    if (!reason.trim() || !state || !city || !neighborhood.trim()) {
      alert('Por favor, preencha todos os campos.');
      return;
    }
  
    setIsSubmitting(true);
  
    // Lógica para capturar a imagem mais recente do pet (similar à lógica do Carteirinha.jsx)
    const petImage = pet.pet_3x4 
      ? `https://vidapet.tech/vidapet/pets/${pet.pet_3x4.split('/').pop()}` 
      : 'default_image_path.jpg'; // Caminho padrão caso não exista uma imagem

    // Imprimindo o caminho completo da foto no console
    console.log('Caminho completo da foto do pet:', petImage);

    const payload = {
      pet_id: pet.petId,  // Ajustando para pet_id, que corresponde ao nome no banco
      title: 'Adoção Pet',
      comments: 'Nenhum comentário adicional.',
      reason,
      state,
      city,
      neighborhood,
      country: 'Brasil',
      image_path: petImage,  // Usando a lógica correta para o caminho da imagem
    };
  
    console.log('Dados enviados para a API:', payload);
  
    try {
      const response = await axios.post(
        'https://vidapet.tech/api/DB/vidapettech/adoption/AdoptionCase.php?action=insert',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log('Resposta da API:', response.data);
  
      if (response.status === 200 && response.data.success) {
        alert('Pet colocado para adoção com sucesso!');
        onClose();
      } else {
        alert('Houve um problema ao tentar colocar o pet para adoção: ' + response.data.message);
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
      alert('Erro ao enviar dados. Tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <ModalBackground>
      <ModalContent>
        <Header>
          <Title>Colocar {pet.petName} para Doação</Title>
          <CloseButton onClick={onClose} />
        </Header>

        <Form>
          <Label>Motivo da Doação:</Label>
          <SmallTextArea value={reason} onChange={e => setReason(e.target.value)} placeholder="Descreva o motivo..." />

          <Label>Estado:</Label>
          <Select value={state} onChange={handleStateChange}>
            <option value="">Selecione o estado</option>
            {states.map((estado) => (
              <option key={estado.id} value={estado.sigla}>
                {estado.nome}
              </option>
            ))}
          </Select>

          <Label>Cidade:</Label>
          <Select value={city} onChange={e => setCity(e.target.value)} disabled={!state}>
            <option value="">Selecione a cidade</option>
            {cities.map((cidade) => (
              <option key={cidade.id} value={cidade.nome}>
                {cidade.nome}
              </option>
            ))}
          </Select>

          <Label>Bairro:</Label>
          <Input value={neighborhood} onChange={e => setNeighborhood(e.target.value)} placeholder="Informe o bairro..." />

          <ButtonContainer>
            <Button onClick={onClose} disabled={isSubmitting}>
              Cancelar
            </Button>
            <Button primary onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? 'Enviando...' : 'Confirmar Doação'}
            </Button>
          </ButtonContainer>
        </Form>
      </ModalContent>
    </ModalBackground>
  );
};

// Estilos ajustados
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 18px;
  color: #333;
`;

const CloseButton = styled(IoMdCloseCircle)`
  font-size: 24px;
  cursor: pointer;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  font-size: 16px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 20px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 20px;
`;

const SmallTextArea = styled.textarea`
  width: 100%;
  height: 60px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  resize: none;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled.button`
  background-color: ${(props) => (props.primary ? '#007bff' : '#f5f5f5')};
  color: ${(props) => (props.primary ? 'white' : '#333')};
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${(props) => (props.primary ? '#0056b3' : '#e0e0e0')};
  }
`;

export default AdoptMe;
