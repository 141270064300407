import React, { useState } from 'react';
import "./estilo.css";

const AddServicePartner = () => {
    const [nome, setNome] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [nomeResponsavel, setNomeResponsavel] = useState('');
    const [telefoneResponsavel, setTelefoneResponsavel] = useState('');
    const [enderecoComercial, setEnderecoComercial] = useState('');
    const [telefoneComercial, setTelefoneComercial] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [site, setSite] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [cidade, setCidade] = useState('');
    const [bairro, setBairro] = useState('');
    const [estado, setEstado] = useState('');
    const [descricao, setDescricao] = useState('');
    const [categoria, setCategoria] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const partnerData = {
            nome,
            nome_fantasia: nomeFantasia,
            nome_responsavel: nomeResponsavel,
            telefone_responsavel: telefoneResponsavel,
            endereco_comercial: enderecoComercial,
            telefone_comercial: telefoneComercial,
            whatsapp,
            site,
            logo_url: logoUrl,
            created_at: new Date().toISOString(),
            cidade,
            bairro,
            estado,
            descricao,
            categoria,
        };

        try {
            const response = await fetch('https:/vidapet.tech/add_partner.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(partnerData),
            });

            const result = await response.json();
            if (!response.ok) throw new Error(result.error);
            // Limpar os campos após o envio
            setNome('');
            setNomeFantasia('');
            setNomeResponsavel('');
            setTelefoneResponsavel('');
            setEnderecoComercial('');
            setTelefoneComercial('');
            setWhatsapp('');
            setSite('');
            setLogoUrl('');
            setCidade('');
            setBairro('');
            setEstado('');
            setDescricao('');
            setCategoria('');

            alert('Parceiro adicionado com sucesso!');
        } catch (error) {
            console.error('Erro ao enviar dados:', error.message);
            alert('Erro: ' + error.message);
        }
    };

    return (
        <div className="form-container">
            <h2>Adicionar Serviço e Parceiro</h2>
            <form onSubmit={handleSubmit} className="form-grid">
                <div className="form-group">
                    <label>Nome:</label>
                    <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} required />
                </div>
                <div className="form-group">
                    <label>Nome Fantasia:</label>
                    <input type="text" value={nomeFantasia} onChange={(e) => setNomeFantasia(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Nome do Responsável:</label>
                    <input type="text" value={nomeResponsavel} onChange={(e) => setNomeResponsavel(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Telefone do Responsável:</label>
                    <input type="text" value={telefoneResponsavel} onChange={(e) => setTelefoneResponsavel(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Endereço Comercial:</label>
                    <input type="text" value={enderecoComercial} onChange={(e) => setEnderecoComercial(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Telefone Comercial:</label>
                    <input type="text" value={telefoneComercial} onChange={(e) => setTelefoneComercial(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>WhatsApp:</label>
                    <input type="text" value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Site:</label>
                    <input type="text" value={site} onChange={(e) => setSite(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Logo URL:</label>
                    <input type="text" value={logoUrl} onChange={(e) => setLogoUrl(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Cidade:</label>
                    <input type="text" value={cidade} onChange={(e) => setCidade(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Bairro:</label>
                    <input type="text" value={bairro} onChange={(e) => setBairro(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Estado:</label>
                    <input type="text" value={estado} onChange={(e) => setEstado(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Descrição:</label>
                    <textarea value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Categoria:</label>
                    <input type="text" value={categoria} onChange={(e) => setCategoria(e.target.value)} />
                </div>
                <button type="submit">Adicionar Parceiro</button>
            </form>
        </div>
    );
};

export default AddServicePartner;
