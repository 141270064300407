import React from 'react';
import styled from 'styled-components';

// Estilos com styled-components
const Container = styled.div`
  padding: 10px 20px;
`;

const AddressMenu = styled.div`
  display: flex;
  flex-direction: row;
`;

const Location = styled.p`
  color: #333;
  font-size: 16px;
  font-weight: bold;
`;

const ArrowIcon = styled.span`
  font-size: 20px;
  color: #ff0000;
`;

export default function Address() {
  return (
    <Container>
      <AddressMenu>
        <Location>SELECIONE SEU ENDEREÇO</Location>

        <ArrowIcon>&darr;</ArrowIcon>
      </AddressMenu>
    </Container>
  );
}
