// src/pages/Login/userService.js
import axios from 'axios';

export const getUserIdFromFirebaseId = async (firebaseId) => {
  try {
    const response = await axios.get(`https://vidapet.tech/sync-user.php?firebaseId=${firebaseId}`);
    console.log('Resposta completa da API:', response); // Verifique toda a resposta
    console.log('Dados da resposta:', response.data); // Verifique os dados especificamente

    // Acessando o userId corretamente
    if (response.data && response.data.userId) {
      const userId = response.data.userId; // Ajuste aqui para acessar diretamente
      return userId;
    } else {
      console.error('Erro: A resposta não contém o userId esperado');
      return null;
    }
  } catch (error) {
    console.error('Erro ao consultar UserID:', error);
    return null;
  }
};

