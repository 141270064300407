import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// Estilos com styled-components para web
const Container = styled.div`
  margin: 20px 0;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 10px 15px 20px;
`;

const Info = styled.div``;

const Title = styled.h2`
  font-size: 23.25px;
  font-weight: bold;
`;

const SubTitle = styled.p`
  color: #999;
`;

const SeeMoreButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const SeeMoreText = styled.span`
  color: red;
`;

const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const OfferList = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
`;

const Item = styled.div`
  min-width: 200px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
`;

const ItemInfo = styled.div`
  margin-top: auto;
  padding: 10px;
`;

const ItemImage = styled.img`
  height: 200px;
  width: 200px;
`;

const ItemTitle = styled.h3`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ItemPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const OldPrice = styled.span`
  margin-left: 5px;
  font-weight: bold;
  color: #999;
  font-size: 16px;
  text-decoration: line-through;
`;

const Price = styled.span`
  color: green;
  font-weight: bold;
  font-size: 18px;
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  cursor: pointer;
  z-index: 10;
  padding: 10px;
  font-size: 24px;
`;

const LeftArrow = styled(Arrow)`
  left: 10px;
`;

const RightArrow = styled(Arrow)`
  right: 10px;
`;

function Offers() {
  const [offers, setOffers] = useState([]);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const offerListRef = useRef(null);
  
  useEffect(() => {
    async function loadOffers() {
      try {
        const response = await fetch('https://vidapet.tech/Market/API/mostrar_10_produtos.php');
        if (!response.ok) {
          throw new Error('Erro na requisição: ' + response.statusText);
        }
        const data = await response.json();
        console.log('Dados dos produtos recebidos:', data);

        if (data.success) {
          setOffers(data.products);
        } else {
          setError(data.message || 'Nenhum produto encontrado.');
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setError('Erro ao carregar produtos.');
      }
    }

    loadOffers();

    // Atualizar a cada 60 segundos
    const intervalId = setInterval(loadOffers, 60000);

    // Limpar o intervalo ao desmontar o componente
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % offers.length);
    }, 3000); // Alterar a cada 3 segundos
    
    return () => clearInterval(intervalId);
  }, [offers.length]);

  useEffect(() => {
    if (offerListRef.current) {
      offerListRef.current.style.transform = `translateX(-${currentIndex * 215}px)`;
    }
  }, [currentIndex]);

  function handleNavigateItem(item) {
    // Implemente a navegação para 'Item'
    console.log('Navigate to Item:', item);
  }

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + offers.length) % offers.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % offers.length);
  };

  return (
    <Container>
      <Header>
        <Info>
          <Title>DESTAQUE</Title>
          <SubTitle>COM OS ÚLTIMOS PRODUTOS.</SubTitle>
        </Info>

        <SeeMoreButton onClick={() => console.log('Navigate to Search')}>
          <SeeMoreText>Ver mais</SeeMoreText>
        </SeeMoreButton>
      </Header>

      <CarouselContainer>
        <LeftArrow onClick={handlePrevClick}>&lt;</LeftArrow>
        <OfferList ref={offerListRef}>
          {error ? (
            <p>{error}</p>
          ) : (
            offers.map(offer => (
              <Item key={offer.id} onClick={() => handleNavigateItem(offer)}>
                <ItemImage src={`https://vidapet.tech/MARKET/Produtos/${offer.image}`} alt={offer.title} />
                <ItemInfo>
                  <ItemTitle>{offer.title}</ItemTitle>
                  <ItemPrice>
                    <Price>{offer.newPrice}</Price>
                    <OldPrice>{offer.price}</OldPrice>
                  </ItemPrice>
                </ItemInfo>
              </Item>
            ))
          )}
        </OfferList>
        <RightArrow onClick={handleNextClick}>&gt;</RightArrow>
      </CarouselContainer>
    </Container>
  );
}

export default Offers;
