import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importa o estilo padrão do Quill
import './PostEditor.css'

const PostEditor = ({ onSave }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPost = { title, content };
    onSave(newPost);
    setTitle('');
    setContent('');
  };

  return (
    <div className='Post-container'>
        
    <form onSubmit={handleSubmit}>
      <input 
        type="text" 
        placeholder="Título" 
        value={title} 
        onChange={(e) => setTitle(e.target.value)} 
        required 
      />
      <ReactQuill 
        value={content} 
        onChange={setContent} 
        placeholder="Digite o conteúdo aqui..." 
      />
      <button type="submit">Salvar</button>
    </form>

    </div>
  );
};

export default PostEditor;
