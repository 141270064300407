import React, { useEffect, useState, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import pinIcon from '../../imagens/icon-Map.png';
import userLocationIconImage from '../../imagens/user-location-icon.png';
import Filter from './Filter';
import {
  Container,
  MapContainerWrapper,
  MapExpandButton,
  RescueList,
  RescueCard,
  RescueImage,
  CardContent,
  CardTitle,
  Comments,
  ButtonRe,
  ActionButtonWrapper,
  FollowButton,
  RescueStatusOverlay,
  ImageModal,
  ModalImage,
  FilterButton,
  FilterContainer,
  ColumnContainer,
  Column,
} from './Components/CSS/styleRescue';
import { FaArrowCircleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const RescueView = () => {
  const [rescueData, setRescueData] = useState([]);
  const [filteredRescueData, setFilteredRescueData] = useState([]);
  const [isMapExpanded, setIsMapExpanded] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState([-15.793889, -47.882778]); // Centralizado em Brasília inicialmente
  const [zoomLevel, setZoomLevel] = useState(5);
  const [rescueStatus, setRescueStatus] = useState([]);
  const [expandedImage, setExpandedImage] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [locationError, setLocationError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Buscar dados de resgate
    fetch('https://vidapet.tech/rescue_list.php')
      .then((response) => response.json())
      .then((data) => {
        const rescueCases = data.filter(
          (item) => item.latitude && item.longitude && (item.latitude !== 0 || item.longitude !== 0)
        );
        setRescueData(rescueCases);
        setFilteredRescueData(rescueCases); // Exibir todos os resgates por padrão
        setRescueStatus(new Array(data.length).fill(''));

        // Tentativa de obter a localização do usuário
        getUserLocation().then((location) => {
          if (!location) {
            setLocationError(true); // Caso não consiga obter a localização
          }
        });

        // Carregar lista de estados
        fetchStates();
      })
      .catch((error) => console.error('Erro ao buscar dados:', error));
  }, []);

  // Função para obter localização do usuário
  const getUserLocation = async () => {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userCoords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(userCoords);
          setMapCenter([userCoords.lat, userCoords.lng]); // Centraliza no usuário
          setZoomLevel(10); // Zoom ajustado para 100km
          console.log('Geolocalização obtida do navegador:', userCoords);
          resolve(userCoords); // Retorna a localização do usuário
        },
        async () => {
          // Fallback para localização via IP usando IPinfo API
          try {
            const response = await fetch('https://ipinfo.io/json?token=73b555d19e1f65');
            const data = await response.json();
            const [lat, lng] = data.loc.split(',').map(Number);

            const ipCoords = { lat, lng };
            setMapCenter([lat, lng]); // Centraliza na localização por IP
            setZoomLevel(9); // Zoom intermediário
            console.log('Localização obtida via IP:', ipCoords);
            resolve(ipCoords);
          } catch (error) {
            console.error('Falha ao obter localização por IP', error);
            const defaultCoords = [-15.793889, -47.882778]; // Centraliza em Brasília
            setMapCenter(defaultCoords);
            setZoomLevel(5); // Zoom afastado em Brasília
            setLocationError(true); // Marcar erro de localização
            console.log('Localização padrão usada: Brasília');
            resolve(null);
          }
        }
      );
    });
  };

  // Função para carregar lista de estados usando a API do IBGE
  const fetchStates = async () => {
    try {
      const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome');
      const data = await response.json();
      setStates(data);
    } catch (error) {
      console.error('Erro ao buscar estados:', error);
    }
  };

  // Função para carregar cidades com base no estado selecionado
  const fetchCities = async (stateCode) => {
    try {
      const response = await fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateCode}/municipios?orderBy=nome`
      );
      const data = await response.json();
      setCities(data);
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
    }
  };

  // Função para obter coordenadas de uma cidade usando uma API de geocodificação
  const getCoordinatesFromCity = async (city, state) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?city=${city}&state=${state}&country=Brazil&format=json`
      );
      const data = await response.json();
      if (data.length > 0) {
        const { lat, lon } = data[0];
        return { latitude: parseFloat(lat), longitude: parseFloat(lon) };
      }
    } catch (error) {
      console.error('Erro ao buscar coordenadas:', error);
    }
    return null; // Caso não encontre as coordenadas
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity(''); // Limpar cidade
    if (state) fetchCities(state);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleFilterChange = (filteredData) => {
    setFilteredRescueData(filteredData);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleMapToggle = () => {
    setIsMapExpanded(!isMapExpanded);
  };

  const handleFollow = (id) => {
    navigate(`/rescues/${id}`);
  };

  const openImage = (imageUrl) => {
    setExpandedImage(imageUrl);
  };

  const closeImage = () => {
    setExpandedImage(null);
  };

  const centerMapOnFilter = useCallback(async () => {
    if (selectedCity && selectedState) {
      const coords = await getCoordinatesFromCity(selectedCity, selectedState);
      if (coords) {
        setMapCenter([coords.latitude, coords.longitude]);
        setZoomLevel(12); // Centralizar e ajustar zoom para cidade
        // Aplicar filtro de cidade na lista de resgates
        const filteredData = rescueData.filter(
          (rescue) => rescue.city === selectedCity && rescue.state === selectedState
        );
        setFilteredRescueData(filteredData);
      }
    }
  }, [selectedCity, selectedState, rescueData]);

  // Quando o filtro for aplicado, centralizar o mapa no estado/cidade escolhido
  useEffect(() => {
    centerMapOnFilter();
  }, [centerMapOnFilter]);

  // Verificar e centralizar no usuário após 6 segundos
  useEffect(() => {
    if (userLocation) {
      const timeoutId = setTimeout(() => {
        setMapCenter([userLocation.lat, userLocation.lng]);
        setZoomLevel(10); // Ajustar o zoom para ver o pin do usuário
        console.log('Recentralizando no pin do usuário após 6 segundos:', userLocation);
      }, 6000);

      return () => clearTimeout(timeoutId); // Limpa o timeout caso o componente seja desmontado
    }
  }, [userLocation]);

  function MapEventHandlers() {
    const map = useMapEvents({
      moveend() {
        const bounds = map.getBounds(); // Obter os limites atuais do mapa

        // Filtrar resgates que estão dentro dos limites do mapa
        const visibleRescueData = rescueData.filter((rescue) => {
          const rescueLatLng = L.latLng(rescue.latitude, rescue.longitude);
          return bounds.contains(rescueLatLng); // Exibir apenas os casos de resgate dentro do mapa
        });

        setFilteredRescueData(visibleRescueData); // Atualizar a lista de resgates visíveis
      },
    });
    return null;
  }

  return (
    <Container>
      {/* Alerta de localização não capturada */}
      {locationError && (
        <div className="alert-location">
          Não conseguimos capturar sua localização. Selecione um ponto no mapa ou use o filtro para escolher a cidade.
        </div>
      )}

      {/* Filtro de Resgates */}
      <FilterButton onClick={toggleFilterVisibility}>
        {isFilterVisible ? 'Ocultar Filtro' : 'Opções de Busca'}
      </FilterButton>
      {isFilterVisible && (
        <FilterContainer>
          {/* Filtro de Estado */}
          <div>
            <label>Estado</label>
            <select value={selectedState} onChange={handleStateChange}>
              <option value="">Selecione um estado</option>
              {states.map((state) => (
                <option key={state.id} value={state.sigla}>
                  {state.nome}
                </option>
              ))}
            </select>
          </div>

          {/* Filtro de Cidade */}
          <div>
            <label>Cidade</label>
            <select value={selectedCity} onChange={handleCityChange} disabled={!selectedState}>
              <option value="">Selecione uma cidade</option>
              {cities.map((city) => (
                <option key={city.id} value={city.nome}>
                  {city.nome}
                </option>
              ))}
            </select>
          </div>

          {/* Filtro de resgates com base no estado e cidade */}
          <Filter
            rescueData={rescueData}
            onFilterChange={handleFilterChange}
            selectedState={selectedState}
            selectedCity={selectedCity}
          />
        </FilterContainer>
      )}

      {/* Mapa de Resgates */}
      <MapContainerWrapper isExpanded={isMapExpanded}>
        <MapContainer center={mapCenter} zoom={zoomLevel} scrollWheelZoom={true} style={{ height: '100%', width: '100%' }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; OpenStreetMap" />
          <MapEventHandlers />
          {userLocation && (
            <Marker position={userLocation} icon={L.icon({ iconUrl: userLocationIconImage, iconSize: [38, 38], iconAnchor: [19, 38] })}>
              <Popup>Sua localização</Popup>
            </Marker>
          )}
          {filteredRescueData.map((rescue) => (
            <Marker key={rescue.id} position={[rescue.latitude, rescue.longitude]} icon={L.icon({ iconUrl: pinIcon, iconSize: [38, 38], iconAnchor: [19, 38] })}>
              <Popup>
                <div className="PopupContent">
                  <RescueImage src={`https://vidapet.tech/${rescue.image_path}`} alt={`Resgate ${rescue.type}`} />
                  <ActionButtonWrapper>
                    <FollowButton onClick={() => handleFollow(rescue.id)}>Acompanhar Caso</FollowButton>
                  </ActionButtonWrapper>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
        <MapExpandButton onClick={handleMapToggle}>{isMapExpanded ? 'Reduzir Mapa' : 'Expandir Mapa'}</MapExpandButton>
      </MapContainerWrapper>

      {/* Exibição de Casos de Resgate */}
      <RescueList>
        {filteredRescueData.map((rescue) => (
          <RescueCard key={rescue.id}>
            <RescueImage 
              src={`https://vidapet.tech/${rescue.image_path}`} 
              alt={`Resgate ${rescue.type}`} 
              onClick={() => openImage(rescue.image_path)} 
            />
            <CardContent>
              <CardTitle>{rescue.title}</CardTitle>
              <Comments>{rescue.comments}</Comments>
              <ActionButtonWrapper>
                <ButtonRe onClick={() => handleFollow(rescue.id)}>
                  Acompanhar Resgate
                  <FaArrowCircleRight />
                </ButtonRe>
              </ActionButtonWrapper>
              <RescueStatusOverlay>
                {rescueStatus[rescue.id] && <span>{`Status: ${rescueStatus[rescue.id]}`}</span>}
              </RescueStatusOverlay>
            </CardContent>
          </RescueCard>
        ))}
      </RescueList>

      {/* Modal para imagem expandida */}
      {expandedImage && (
        <ImageModal onClick={closeImage}>
          <ModalImage src={expandedImage} alt="Imagem Expandida" />
        </ImageModal>
      )}
    </Container>
  );
};

export default RescueView;
