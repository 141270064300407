import React, { useState } from 'react';
import styled from 'styled-components';
import { auth, provider } from '../../firebase';
import { FcGoogle } from 'react-icons/fc';

const Login = ({ closeLogin, openRegistro, openEsqueciSenha }) => {
  const [error, setError] = useState('');

  const handleGoogleLogin = () => {
    auth.signInWithPopup(provider)
      .then(() => {
        closeLogin(); // Fechar o menu após o login
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <Wrapper>
      <LoginSection>
        <Divider />

        {/* Botão de Login com Google */}
        <GoogleLoginButton onClick={handleGoogleLogin}>
          <FcGoogle className='logo' />
          <p>Acesso com Google</p>
        </GoogleLoginButton>

        {/* Se houver erro, exibe */}
        {error && <ErrorBox>{error}</ErrorBox>}

        <Divider />
      </LoginSection>

      <AuxiliaryLinks>
        {/* Mantém os links de registro e de esqueceu senha */}
       </AuxiliaryLinks>
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.div`
  width: 100%;
  padding: 8px 10px;
  background: #333;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #555;
  margin: 8px 0;
`;

const GoogleLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #db4437;
  width: 100%;
  padding: 8px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;

  .logo {
    margin-right: 5px;
    font-size: 14px;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  &:hover {
    background-color: #c5372c;
  }
`;

const AuxiliaryLinks = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const Link = styled.span`
  font-size: 12px;
  color: #FF7F50;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ErrorBox = styled.div`
  color: red;
  margin-top: 8px;
  font-size: 12px;
  text-align: center;
`;
