import React from 'react';
import styled from 'styled-components';

const SearchInput = ({ value, onChange, placeholder, onSearch }) => {
  return (
    <CenteredContainer>
      <Container>
        <StyledInput
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onSearch();
            }
          }}
        />
        <SearchButton onClick={onSearch}>Buscar</SearchButton>
      </Container>
    </CenteredContainer>
  );
};

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; page */
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 8px;
  margin: 16px;
  width: 600px;
`;

const StyledInput = styled.input`
  flex: 1;
  font-size: 16px;
  color: #333;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 8px;
`;

const SearchButton = styled.button`
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  padding: 8px 16px;
  cursor: pointer;
  margin-left: 8px;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

export default SearchInput;
