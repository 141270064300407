import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import UserChat from '../Dashboard/Components/Chat/UserChat'; // Caminho da importação corrigido

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentFeature, setCurrentFeature] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showImageCarousel, setShowImageCarousel] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false); // Estado para controlar a visibilidade do chat
  const navigate = useNavigate();
  const videoRef = useRef(null); // Referência para o vídeo

  const features = {
    digitalCard: {
      title: "Carteirinha Digital",
      description: [
        "Você adotou um cachorro chamado Tobby e começou a acumular vários documentos e exames veterinários.",
        "Com a Carteirinha Digital da VidaPet.Tech, tudo é armazenado digitalmente, e basta abrir o app para acessar o histórico completo.",
        "Se você estiver viajando e algo acontecer com Tobby, qualquer veterinário pode acessar esses dados e garantir um atendimento rápido e eficiente."
      ],
      link: "/dashboard",
      images: [
        "/imagens/Carteira01.jpeg",
        "/imagens/Carteira02.jpeg",
        "/imagens/Carteira03.jpeg",       
        "/imagens/Carteira04.jpeg",
        "/imagens/Carteira05.jpeg"
      ]
    },
    telemedicine: {
      title: "Telemedicina Veterinária",
      description: [
        "Luna, sua gatinha, começa a agir de forma estranha e está inquieta durante a noite.",
        "Com a telemedicina VidaPet.Tech, em minutos você fala com um veterinário online, que acalma você e te orienta sobre os cuidados emergenciais."
      ],
      link: "/telemedicine",
      images: []
    },
    rescue: {
      title: "Resgate Inteligente",
      description: [
        "Você chega em casa após um dia exaustivo de trabalho e percebe que, por causa da tempestade no fim do dia, o portão da garagem abriu.",
        "Seu pet Rex, assustado com o barulho dos trovões, fugiu de casa.",
        "Imediatamente, você acessa o Resgate Inteligente e cria um alerta, que notifica todos os usuários da região.",
        "Em poucos minutos, uma pessoa próxima avista Rex e envia a localização exata dele através do aplicativo.",
        "Graças à geolocalização e à rede de usuários, você reencontra Rex rapidamente e o traz de volta para casa, em segurança."
      ],
      link: "/resgate",
      images: []
    },
    marketplace: {
      title: "Marketplace para Pets",
      description: [
        "Você está procurando uma ração específica para seu pet, mas não consegue encontrá-la nas lojas perto de você.",
        "Com o Marketplace VidaPet.Tech, você encontra rapidamente a ração que precisa e faz o pedido online, sem complicações."
      ],
      link: "/servico",
      images: []
    }
  };

  useEffect(() => {
    // Inicia a exibição do chat após 35 segundos
    const chatTimeout = setTimeout(() => {
      setIsChatVisible(true);
    }, 12000); // 35 segundos

    return () => {
      clearTimeout(chatTimeout);
    };
  }, []);

  const openModal = (feature) => {
    setCurrentFeature(features[feature]);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentFeature(null);
  };

  const openImageCarousel = (index) => {
    setCurrentImageIndex(index);
    setShowImageCarousel(true);
  };

  const closeImageCarousel = () => {
    setShowImageCarousel(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentFeature.images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + currentFeature.images.length) % currentFeature.images.length);
  };

  const handleNavigate = (link) => {
    navigate(link);
    closeModal();
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <div className="home-new-container">
      <section className="intro-section">
        <div className="background-video">
          <video ref={videoRef} autoPlay loop muted>
            <source src="/imagens/eleefon21.mp4" type="video/mp4" />
            Seu navegador não suporta vídeos.
          </video>
        </div>
        <div className="overlay-text">
          <h1>Bem-vindo ao Futuro do Cuidado com Pets</h1>
          <p>VidaPet.Tech - Tecnologia e carinho para seu pet, onde quer que você esteja.</p>
          <button className="saiba-mais-btn" onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}>
            Saiba Mais
          </button>
        </div>
      </section>

      <section className="features-section">
        <h2>Como Podemos Ajudar Você no Dia a Dia</h2>
        <div className="features-grid">
          {Object.keys(features).map((feature, index) => (
            <div className="feature-card" key={index}>
              <img src={`/imagens/Produto0${index + 1}.jpeg`} alt={features[feature].title} className="feature-img" />
              <h3>{features[feature].title}</h3>
              <button className="saiba-mais-btn" onClick={() => openModal(feature)}>Saiba Mais</button>
            </div>
          ))}
        </div>
      </section>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={closeModal}>&times;</button>
            {currentFeature && (
              <>
                <h2>{currentFeature.title}</h2>
                <ul>
                  {currentFeature.description.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                {currentFeature.images.length > 0 && (
                  <div className="carteirinha-images">
                    {currentFeature.images.map((src, index) => (
                      <img
                        src={src}
                        alt={`Imagem ${index + 1}`}
                        key={index}
                        className="carteirinha-img"
                        onClick={() => openImageCarousel(index)}
                      />
                    ))}
                  </div>
                )}
                <button className="cta-button small-btn" onClick={() => handleNavigate(currentFeature.link)}>
                  Conheça Agora!
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {showImageCarousel && (
        <div className="carousel-overlay">
          <div className="carousel-content">
            <button className="close-btn" onClick={closeImageCarousel}>&times;</button>
            <button className="prev-btn" onClick={prevImage}>❮</button>
            <img src={currentFeature.images[currentImageIndex]} alt={`Imagem ${currentImageIndex + 1}`} className="carousel-img" />
            <button className="next-btn" onClick={nextImage}>❯</button>
          </div>
        </div>
      )}

      <section className="cta-final-section">
        <button className="cta-button" onClick={handleLoginRedirect}>Cadastre-se Grátis e Comece Agora!</button>
      </section>

      <section className="testimonials-section">
        <h2>O Que Nossos Usuários Dizem</h2>
        <div className="testimonials-container">
          <div className="testimonial">
            <p>"Fui o primeiro usuário VidaPet.Tech e uso com minha YorkShire, Jade. Tudo está guardado lá desde o nascimento dela. Numa emergência, temos todos os dados de saúde dela na Palma da Mão"</p>
            <span>- Anderson Santos - Itaboraí - RJ</span>
          </div>
          <div className="testimonial">
            <p>"O resgate interativo salvou a vida do meu cachorro, um vizinho do bairro tirou a foto dele pelo VidaPet.Tech perdido indo para auto-estrada, com o Mapa Interativo com Geolocalização agente pode resgatar ele rápido!"</p>
            <span>- Lucas, tutor do Costelinha - Maricá - RJ</span>
          </div>
        </div>
      </section>

      <section className="cta-final-section">
          <h2>Pronto para Transformar o Cuidado com Seu Pet?</h2>
          <p>
            VidaPet.Tech traz para você tecnologia que facilita o cuidado e o bem-estar do seu animal. Organize a saúde, tenha sempre acesso a informações importantes e nunca mais se preocupe com a segurança do seu pet.
          </p>
          <button className="cta-button" onClick={handleLoginRedirect}>Experimente Agora Grátis!</button>
      </section>

      {isChatVisible && <UserChat />} {/* Exibe o chat da assistente virtual Lili quando isChatVisible for true */}

    </div>
  );
};

export default Home;
