import React, { useState, useEffect } from 'react';

const ChatSystemNotifications = ({ userId, onNotificationSelect, minimized }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userId) {
      fetchNotifications();
    }
  }, [userId]);

  const fetchNotifications = () => {
    setLoading(true);
    setError(null);

    fetch(`https://vidapet.tech/api/DB/vidapettech/System/messages_notifications.php?recipientId=${userId}`)
      .then(response => response.json())
      .then(data => {
        if (data.notifications) {
          setNotifications(data.notifications);
        } else {
          setNotifications([]);
        }
        setLoading(false);
      })
      .catch(error => {
        setError('Erro ao buscar notificações');
        setLoading(false);
      });
  };

  const handleNotificationClick = (notification) => {
    onNotificationSelect(notification); 
    fetch('https://vidapet.tech/api/DB/vidapettech/System/messages_notifications.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        notificationId: notification.id,
        receivedId: userId
      }),
    }).then(() => {
      setNotifications((prevNotifications) =>
        prevNotifications.map((n) =>
          n.id === notification.id ? { ...n, is_read: 1 } : n
        )
      );
    });
  };

  if (loading) {
    return <p>Carregando notificações...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className={`notifications-section ${minimized ? 'minimized' : ''}`}>
      <div className="notification-header">
        <h4></h4>
      </div>
      <ul className="notification-list">
        {notifications.length === 0 ? (
          <li className="notification-item"></li>
        ) : (
          notifications.map(notification => (
            <li
              key={notification.id}
              className={`notification-item ${notification.is_read ? 'read' : ''}`}
              onClick={() => handleNotificationClick(notification)}
            >
              {notification.description} {/* Mostrando o resumo da notificação */}
              {notification.is_read && <span className="read-label"> - Lida</span>}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default ChatSystemNotifications;
