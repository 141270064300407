import React, { useState, useEffect } from 'react';

const AdoptionFeeds = ({ adoptionId, refreshFeeds }) => {
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    console.log('AdoptionFeeds useEffect triggered');
    console.log('adoptionId:', adoptionId);
    console.log('refreshFeeds:', refreshFeeds);
    
    fetchFeeds();
  }, [adoptionId, refreshFeeds]); // Atualiza sempre que o feed ou o ID mudar

  const fetchFeeds = () => {
    console.log(`Buscando feeds para o caso de adoção com id: ${adoptionId}`);
    
    const apiUrl = `https://vidapet.tech/api/DB/vidapettech/adoption/adoptionfeeds.php?adoption_id=${adoptionId}`;
    console.log('URL da API para buscar feeds:', apiUrl);

    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        console.log('Dados processados da API de feeds:', data);
        if (data.success) {
          // Filtrando para exibir apenas os feeds que não foram deletados
          const validFeeds = data.feeds.filter(feed => feed.is_deleted === 'não');
          console.log('Feeds válidos recebidos com sucesso:', validFeeds);
          setFeeds(validFeeds); // Definindo os feeds válidos recebidos
        } else {
          console.error('Erro ao buscar os feeds:', data.message);
        }
      })
      .catch(error => {
        console.error('Erro ao buscar os feeds:', error);
      });
  };

  const handleDelete = async (feedId) => {
    // Adicionar confirmação
    const confirmDelete = window.confirm("Tem certeza de que deseja deletar este comentário?");
    
    if (!confirmDelete) {
      return; // Se o usuário cancelar, não continua a ação
    }

    const payload = { feed_id: feedId };
    console.log('Enviando requisição para deletar feed:', payload);

    try {
      const response = await fetch('https://vidapet.tech/api/DB/vidapettech/adoption/deletefeed.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      console.log('Resposta ao deletar comentário:', data);

      if (data.success) {
        alert('Comentário marcado como deletado com sucesso!');
        fetchFeeds(); // Atualiza os feeds após marcar como deletado
      } else {
        alert('Erro ao deletar o comentário.');
      }
    } catch (error) {
      console.error('Erro ao deletar o comentário:', error);
    }
  };

  return (
    <div className="adoption-feeds">
      <h3>Comentários sobre o caso de adoção</h3>
      {feeds.length > 0 ? (
        feeds.map((feed, index) => (
          <div key={index} className="feed-item">
            <p><strong>Usuário ID {feed.sender_id}</strong>: {feed.message}</p>
            <p><small>{new Date(feed.created_at).toLocaleString()}</small></p>
            <button onClick={() => handleDelete(feed.id)}>Deletar</button>
          </div>
        ))
      ) : (
        <p>Nenhum comentário ainda.</p>
      )}
    </div>
  );
};

export default AdoptionFeeds;
