// BlogList.js
import React from 'react';
import { Link } from 'react-router-dom';
import './BlogEstilo.css';

const blogPosts = [
  {
    id: 1,
    title: 'VidaPet.Tech é Selecionada para o Programa de Aceleração do Ibmec Hubs Rio de Janeiro',
    date: '30/09/2024',
    category: 'Novidade',
    summary: 'VidaPet.Tech foi escolhida para integrar o renomado programa de aceleração do Ibmec Hubs Rio de Janeiro, um dos principais centros de inovação do Brasil.',
    imageUrl: 'https://midias.jb.com.br/_midias/jpg/2021/04/07/ibmec-592121.jpg',
  },
  {
    id: 2,
    title: 'Quantas vacinas um cão precisa tomar durante sua vida?',
    date: '14/09/2024',
    category: 'Saúde Pet',
    summary: 'Saiba quais são as principais vacinas que um cão precisa tomar e com que frequência.',
    imageUrl: 'https://labovet.com.br/wp-content/uploads/2019/03/vacina_caes_widexl.png',
  },
  {
    id: 3,
    title: 'The Future of Business Strategy',
    date: 'August 18, 2024',
    category: 'Marketing',
    summary: 'Discover evolving business strategies for sustainable growth.',
    imageUrl: 'path_to_image.jpg',
  },
];

const BlogList = () => {
  return (
    <div className="blog-list">
      <h2>Blog & Resources</h2>
      <div className="other-posts">
        {blogPosts.map((post, index) => (
          <div key={post.id} className="blog-post">
            <img src={post.imageUrl} alt={post.title} />
            <div className="blog-post-content">
              <span className="blog-post-date">{post.date}</span>
              <span className="blog-post-category">{post.category}</span>
              <h3>{post.title}</h3>
              <p>{post.summary}</p>
              <Link to={`/post/${post.id}`}>
                <button>Read More</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
