import React, { useState } from 'react';
import { auth } from '../../firebase';
import { Cont} from './CSS/LoginStyles';
import logo from '../../imagens/logoOficial2024.png';

const EsqueciSenha = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault(); 
    try {
      await auth.sendPasswordResetEmail(email);
      setMessage('Email enviado com sucesso.');
    } catch (error) {
      setMessage('Parece que o endereço de email inserido não está correto. Por favor, verifique e tente novamente.');
    }
  };

  return (
    <Cont>
        <div className='wrapper'>  

        <div className="signup-logo">
          <a href="/home">
            <img src={logo} alt="VidaPet.Tech Logo" />
          </a>
        </div>

        <h1>Esqueceu a senha?</h1>
        <p>Informe seu e-mail cadastrado no Vida Pet para enviarmos as instruções de redefinição da senha.</p>
        <form onSubmit={handleResetPassword}>
          <input
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type='submit' className='buttonEntrar'>Enviar</button>
        </form>
        <div>
          {message && <p>{message}</p>}
        </div>
      </div>
    </Cont>
  );
};

export default EsqueciSenha;

