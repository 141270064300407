import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../Resgate/Components/RescueDetails.css';
import Gallery from './Gallery';

const ServiceDetails = ({serviceId}) => {
  const { id } = useParams();
  const [service, setService] = useState(null);
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null);


  
 

useEffect(() => {
  const fetchServiceDetails = async () => {
    try {
      const response = await fetch(`https://vidapet.tech/servicos.php?id=${id}`);
      if (!response.ok) throw new Error('Erro na resposta da rede');
      
      const data = await response.json();
      
      // Supondo que data é um array e você quer encontrar o serviço pelo id
      const selectedService = data.find(s => s.id === id);
      
      if (selectedService) {
        setService(selectedService);
      } else {
        setError('Serviço não encontrado.');
      }
    } catch (error) {
      setError('Erro ao buscar os dados do serviço. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  fetchServiceDetails();
}, [id]);

if (loading) {
  return <div>Carregando...</div>;
}

if (error) {
  return <div>{error}</div>;
}

if (!service) {
  return <div>Serviço não encontrado.</div>;
}

  const shareOnWhatsApp = () => {
    const url = window.location.href;
    const message = `Olá! Encontrei um serviço que pode ser útil:\n\n${service.name}\n${url}\n\nVisite para mais informações!`;
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');
  };

  const handleShareOnFacebook = () => {
    const url = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
  };

  return (
    <div className="container1">
      <div className="header1">
      </div>
      <div className="content-wrapper">

        <div className="image-container">
          <img src={service.logo_url} alt={`Serviço ${service.nome}`} />
          <h3>{service.nome}</h3>
        </div>

        <div className="details-section">
      <h2>Responsável: {service.nome_responsavel}</h2>
      <p>{service.descricao}</p>
      <address>
        <p><strong>Endereço:</strong> {service.endereco_comercial}</p>
        <p><strong>Cidade:</strong> {service.cidade}</p>
        <p><strong>Bairro:</strong> {service.bairro}</p>
      </address>

      <p><strong>WhatsApp:</strong> {service.whatsapp}</p>
      <p><strong>Site:</strong> <a href={service.site} target="_blank" rel="noopener noreferrer">{service.site}</a></p>
    </div>


        <div className="share-button">
          <button className="buttonWha" onClick={shareOnWhatsApp}>Compartilhar no WhatsApp</button>
          <button className="buttonFace" onClick={handleShareOnFacebook}>Compartilhar no Facebook</button>
        </div>
      </div>

      <div>
            <h1>Minha Galeria de Fotos</h1>
            <Gallery serviceId={service.id} />
        </div>

    </div>
  );
};

export default ServiceDetails;
