import React, { useState, useEffect } from 'react';
import './estilo.css';

const AddPhoto = () => {
    const [serviceId, setServiceId] = useState('');
    const [fotoUrl, setFotoUrl] = useState('');
    const [fotoLocal, setFotoLocal] = useState(null);
    const [dataUpload, setDataUpload] = useState('');
    const [services, setServices] = useState([]);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await fetch('http://vidapet.tech/get_services.php');
                const data = await response.json();
                setServices(data);
            } catch (error) {
                console.error('Erro ao buscar serviços:', error);
            }
        };

        fetchServices();
    }, []);

    const handleFileChange = (e) => {
        setFotoLocal(e.target.files[0]); // Captura o arquivo selecionado
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Verifica se pelo menos um dos campos está preenchido
        if (!fotoUrl && !fotoLocal) {
            alert('Por favor, preencha pelo menos o campo "Foto URL" ou "Foto Local".');
            return;
        }

        const formData = new FormData();
        formData.append('service_id', serviceId);
        formData.append('foto_url', fotoUrl ? fotoUrl : null); // Se fotoUrl estiver vazio, envia null
        formData.append('foto_local', fotoLocal ? fotoLocal : null); // Se fotoLocal estiver vazio, envia null
        formData.append('data_upload', dataUpload);

        try {
            const response = await fetch('http://vidapet.tech/add_photo.php', {
                method: 'POST',
                body: formData, // Envia o FormData
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.error);
            }

            // Limpar os campos após o envio
            setServiceId('');
            setFotoUrl('');
            setFotoLocal(null);
            setDataUpload('');

            alert('Foto adicionada com sucesso!');
        } catch (error) {
            console.error('Erro ao enviar dados:', error.message);
            alert('Erro: ' + error.message);
        }
    };

    return (
        <div className="form-container">
            <h2>Adicionar Foto</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Service:</label>
                    <select
                        value={serviceId}
                        onChange={(e) => setServiceId(e.target.value)}
                        required
                    >
                        <option value="">Selecione um serviço</option>
                        {services.map((service) => (
                            <option key={service.id} value={service.id}>
                                {service.id} - {service.nome}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label>Foto URL:</label>
                    <input
                        type="text"
                        value={fotoUrl}
                        onChange={(e) => setFotoUrl(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Foto Local:</label>
                    <input
                        type="file"
                        onChange={handleFileChange} // Chama a função ao selecionar um arquivo
                    />
                </div>
                <div className="form-group">
                    <label>Data de Upload:</label>
                    <input
                        type="date"
                        value={dataUpload}
                        onChange={(e) => setDataUpload(e.target.value)}
                    />
                </div>
                <button type="submit">Adicionar Foto</button>
            </form>
        </div>
    );
};

export default AddPhoto;
