import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Filter = ({ rescueData, onFilterChange }) => {
  const [pet_type, setPetType] = useState('');
  const [distance, setDistance] = useState(10);
  const [timeFrame, setTimeFrame] = useState('');

  useEffect(() => {
    // Aplica o filtro sempre que o valor da distância, tipo de pet ou intervalo de tempo mudar
    applyFilter();
  }, [pet_type, distance, timeFrame]);

  const applyFilter = async () => {
    let filteredData = [...rescueData];

    // Aplicar filtro de tipo de pet
    if (pet_type) {
      filteredData = filteredData.filter(rescue => rescue.pet_type === pet_type);
    }

    // Aplicar filtro de distância (usando a localização do usuário)
    const userLocation = await getUserLocation();
    if (distance && userLocation) {
      const { latitude, longitude } = userLocation;
      filteredData = filteredData.filter(rescue => {
        const rescueDistance = calculateDistance(rescue.latitude, rescue.longitude, latitude, longitude);
        return rescueDistance <= distance;
      });
    }

    // Aplicar filtro de data
    if (timeFrame) {
      const currentDate = moment();
      filteredData = filteredData.filter(rescue => {
        const rescueDate = moment(rescue.capture_datetime);
        const diffHours = currentDate.diff(rescueDate, 'hours');
        if (timeFrame === '12h' && diffHours <= 12) return true;
        if (timeFrame === '7d' && diffHours <= 7 * 24) return true;
        if (timeFrame === '30d' && diffHours <= 30 * 24) return true;
        return false;
      });
    }

    // Passar os dados filtrados de volta para o componente pai
    onFilterChange(filteredData);
  };

  const getUserLocation = async () => {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Erro ao obter localização do usuário:', error);
          resolve(null);
        }
      );
    });
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  return (
    <FilterContainer>
      <SelectContainer>
        <label htmlFor="pet_type">Tipo de Pet:</label>
        <select id="pet_type" value={pet_type} onChange={(e) => setPetType(e.target.value)}>
          <option value="">Selecione um tipo de pet</option>
          <option value="cachorro">Cachorro</option>
          <option value="gato">Gato</option>
          <option value="outros">Outros</option>
        </select>
      </SelectContainer>
      <SelectContainer>
        <label htmlFor="distance">Distância (km): {distance} km</label>
        <input
          type="range"
          id="distance"
          min="0"
          max="100"
          value={distance}
          onChange={(e) => setDistance(Number(e.target.value))}
        />
      </SelectContainer>
      <SelectContainer>
        <label htmlFor="timeFrame">Data:</label>
        <select id="timeFrame" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
          <option value="">Selecione um período</option>
          <option value="12h">Últimas 12 horas</option>
          <option value="7d">Últimos 7 dias</option>
          <option value="30d">Últimos 30 dias</option>
        </select>
      </SelectContainer>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 20px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    font-weight: bold;
  }

  select,
  input {
    padding: 8px;
    border: 2px solid #ccc;
    border-radius: 49px;
    width: 202px;
    background: azure;
  }
`;

export default Filter;
