import React from 'react';
import styled from 'styled-components';

// Estilos com styled-components
const CouponButton = styled.button`
  background-color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
`;

const Divisor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  width: 40px;
  height: 40px;
  background-color: #ccc;
  margin-right: 10px;
`;

const CouponInfo = styled.div`
  flex: 1;
`;

const Title = styled.h3`
  color: #333;
  font-size: 16px;
  font-weight: bold;
`;

const Validity = styled.p`
  color: #666;
  font-size: 14px;
`;

const ArrowIcon = styled.span`
  font-size: 20px;
  color: #ff0000;
`;

function DiscountCoupon() {
  return (
    <CouponButton onClick={() => {}}>
      <Divisor>
        <Content>
          <Logo />
          <CouponInfo>
            <Title>Cupom de R$10</Title>
            <Validity>Válido até as 17:30</Validity>
          </CouponInfo>
        </Content>

        <ArrowIcon>&rarr;</ArrowIcon>
      </Divisor>
    </CouponButton>
  );
}

export default DiscountCoupon;
