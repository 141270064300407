import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";

// Use as novas configurações geradas pelo Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyCwXKFpyd8SAzwk-d5GJCCh5g0CFYemASc",
  authDomain: "vdpttech.firebaseapp.com",
  projectId: "vdpttech",
  storageBucket: "vdpttech.firebasestorage.app",
  messagingSenderId: "55331170521",
  appId: "1:55331170521:web:e82b54d24983c62b20b6c8",
  measurementId: "G-YN9ZNXP5F9"
};

// Inicialize o Firebase com as novas credenciais
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();

// Lógica para obter e armazenar o firebaseId
let firebaseId = localStorage.getItem('firebaseId');
if (!firebaseId) {
  auth.onAuthStateChanged((user) => {
    if (user) {
      firebaseId = user.uid;
      localStorage.setItem('firebaseId', firebaseId);
    }
  });
}

export { auth, provider, storage, firebaseId };
export default db;
