import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import serviceIcon from '../../../imagens/user-location-icon.png'; // Caminho para o ícone do serviço

const ServiceMap = () => {
  const [userLocation, setUserLocation] = useState(null);

  // Exemplo de serviços com localizações fixas
  const services = [
    {
      id: 1,
      name: 'Serviço A',
      description: 'Descrição do Serviço A',
      latitude: -22.936889, // 22°55'12.8"S
      longitude: -42.820972, // 42°49'15.5"W
    },
    {
      id: 2,
      name: 'Serviço B',
      description: 'Descrição do Serviço B',
      latitude: -22.911306, // 22°54'40.7"S
      longitude: -42.933500, // 42°56'00.6"W
    },
  ];

  useEffect(() => {
    // Obter a localização do usuário
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error('Erro ao obter a localização:', error);
      }
    );
  }, []);

  const icon = L.icon({
    iconUrl: serviceIcon,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
  });

  return (
    <div style={{ height: '500px', width: '100%' }}>
      <MapContainer center={userLocation || [-22.933754, -42.894540]} zoom={12} scrollWheelZoom={true} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {userLocation && (
          <Marker position={[userLocation.lat, userLocation.lng]} icon={icon}>
            <Popup>Sua localização</Popup>
          </Marker>
        )}
        {services.map(service => (
          <Marker key={service.id} position={[service.latitude, service.longitude]} icon={icon}>
            <Popup>
              <div>
                <h3>{service.name}</h3>
                <p>{service.description}</p>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default ServiceMap;
