import React, { useState } from 'react';
import './SignUp.css'; 
import { auth, provider } from '../../../firebase';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';
import logo from '../../../imagens/logoOficial2024.png';
import { FcGoogle } from "react-icons/fc";

const SignUp = () => {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const [passwordStrength, setPasswordStrength] = useState(' ');
    const [agreed, setAgreed] = useState(false); 

    const handleTogglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else if (field === 'confirmPassword') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handlePasswordChange = (password) => {
        setPassword(password);
        if (password.length < 6) {
            setPasswordStrength('Fraca');
        } else if (password.length < 10) {
            setPasswordStrength('Média');
        } else {
            setPasswordStrength('Forte');
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        setError(''); 

        if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setError('Por favor, insira um email válido.');
            return;
        }

        if (password !== confirmPassword) {
            setError('As senhas não coincidem.');
            return;
        }

        try {
            const response = await axios.post('https://vidapet.tech/api/Login/cadastroCheckEmail.php', { email });

            if (response.data.status === 'error') {
                setError('Email já está cadastrado. Faça login ou recupere a senha.');
                return;
            }

            const userData = {
                email,
                displayName: userName,
                password, // Send the password to the backend
            };

            const backendResponse = await axios.post('https://vidapet.tech/sync-user.php', userData);

            if (backendResponse.data.status === 'error') {
                setError('Erro ao registrar no backend: ' + backendResponse.data.message);
                return;
            }

            const { user } = await auth.createUserWithEmailAndPassword(email, password);
            await user.updateProfile({ displayName: userName });

            // Automatic login after registration
            await auth.signInWithEmailAndPassword(email, password);
            setSuccess('Cadastro realizado com sucesso.');

            // Reload the page after successful registration and authentication
            setTimeout(() => {
                window.location.reload(); 
            }, 2000);

        } catch (error) {
            setError('Erro no processo de cadastro: ' + error.message);
        }
    };

    // Função de login com Google
  const handleGoogleLogin = () => {
    auth.signInWithPopup(provider)
      .then(async (result) => {
        const user = result.user;

        const profileInfo = {
          firebaseId: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        };

        const backendResponse = await axios.post('https://vidapet.tech/sync-user.php', profileInfo);

        if (backendResponse.data.status === 'success') {
          navigate('/dashboard');
        } else {
          setError('Erro ao sincronizar com o backend: ' + backendResponse.data.message);
        }
      })
      .catch((error) => {
        setError('Erro no login com Google: ' + error.message);
      });
  };

    return (
        <div className="signup-container">
            <div className="signup-card">
            <div className="signup-logo">
          <a href="/home">
            <img src={logo} alt="VidaPet.Tech Logo" />
          </a>
        </div>
                <h1>Inscrever-se</h1>
                <p>Insira suas credenciais para continuar</p>

                <div className='social-icons'>
          <button className='buttonGoogle' onClick={handleGoogleLogin}><FcGoogle size={25} />Registre-se com o Google</button>
        </div>

        <br></br>
        <p>_______________OU_______________</p>


                <form onSubmit={handleRegister}>
                    <div className="input-group">
                        <input
                            type='text'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder='Nome do usuário'
                            required
                        />
                    </div>

                    <input
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Digite seu Email'
                        required
                    />

                    <div className="password-group">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => handlePasswordChange(e.target.value)}
                            placeholder='Digite sua Senha'
                            required
                        />
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder='Confirmar senha'
                            required
                        />
                        <span className={`password-strength ${passwordStrength.toLowerCase()}`}>
                            {passwordStrength}
                        </span>
                    </div>

                    <div className="checkbox-group">
                        <input
                            type="checkbox"
                            name="agreed"
                            checked={agreed}
                            onChange={(e) => setAgreed(e.target.checked)}
                            required
                        />
                        <label htmlFor="agreed" className="signup-label">
                            Concordo com os <a href="/terms">Termos e Condições</a>
                        </label>
                    </div>

                    <button type="submit" className="signup-button">
                        Inscrever-se
                    </button>
                </form>
                <p>Já tem uma conta? <a href="/login">Conecte-se</a></p>
            </div>
        </div>
    );
};

export default SignUp;
