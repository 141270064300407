import styled from 'styled-components';
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";

export const PasswordFaLock = styled(FaLock)`
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  cursor: pointer; 
`;

export const PasswordFaEyeSlash = styled(FaEyeSlash)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  cursor: pointer;
`;

export const PasswordFaEye = styled(FaEye)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  cursor: pointer;
`;

const ErrorBox = styled.div`
  color: red;
  margin-top: 10px;
  font-size: 20px;
`;
const SucessoBox = styled.div`
  color: green;
  margin-top: 10px;
  font-size: 20px;
`;

export const Cont = styled.div`
  display: flex;
 justify-content: center;
 align-items: center;
background-color: #f2f5f9;
    margin-top: -60px;
  z-index: 10;
 position: fixed; 
  width: 100%;  
  

  .wrapper {
      background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    margin-top: 20px;

    h1 {
      color: #fc7d54;
    font-size: 1.5rem;
    margin-bottom: 10px;
    }
  }
    .wrapperRegistro {
    width: 420px;
    background: black;
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;

    h1 {
      font-size: 30px;
      text-align: center;
    }
  }

  .message{
  margin-top: 23px;
  background-color: dimgray;
  padding: 14px;

  }

  .confirm{
  display: grid;
  text-align: initial;
  color: darkgray;

  p {
  padding:10px;
  margin-bottom: 12px;
  }

  }

  .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;

    input {
     width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
    }

    ::placeholder {
      color: black;
    }

    .icon {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
    }

    .icon1 {
      position: absolute;
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
    }
  }

  .remember-forgot {
    display: flex;
    justify-content: end;
    font-size: 14.5px;  
  }

  .buttonRe{
    background: border-box;
    color: #fc7d54;

    &:hover {
      text-decoration: underline;
    }

     &:active {
      transform: scale(0.9);
    }
    
  }

  .buttonEntrar {
    width: 100%;
    height: 45px;
    background: #fc7d54;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 700;
    font-size: 20px;

    &:active {
      transform: scale(0.9);
    }
  }

  .register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;

    p a {
      color: #ffc107;
      text-decoration: none;
      font-weight: 600;
    }

    p a:hover {
      text-decoration: underline;
    }
  }

  .logo {
    margin: 10px;
    font-size: 50px;

    &:hover {
      color: #fff;
    }

    &:active {
      transform: scale(0.8);
    }
  }
 

  @media (max-width: 750px) {
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;

    .wrapper {
      width: 350px;
    }

    .close {
      color: white;
      margin-left: 17rem;
    }
  }
`;
