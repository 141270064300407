import React, { useState } from 'react';
import { auth, provider } from "../../../firebase";
import { FaUser } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { setUserLoginDetails } from "../UserInfo";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import LoginBackEnd from './LoginBackEnd';
import { Cont, PasswordFaLock, PasswordFaEyeSlash, PasswordFaEye } from '../CSS/LoginStyles';
import logo from '../../../imagens/logoOficial2024.png';

const Login = ({ closeLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [profileInfo, setProfileInfo] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();    
  const [message, setMessage] = useState('');

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleLogin = () => {
    auth.signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        setProfileInfo({
          name: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          firebaseId: user.uid
        });

        dispatch(setUserLoginDetails({
          name: user.displayName,
          email: user.email,
          photo: user.photoURL
        }));

        // Obtém o token de ID do Firebase
        user.getIdToken().then((token) => {
          // Redireciona para o deep link com o token
          window.location.href = `yourapp://login?token=${token}`;
        });

        closeLogin();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
        const response = await auth.signInWithEmailAndPassword(email, password);
        const user = response.user;
        const name = user.displayName || '';
        const photo = user.photoURL || '';
        const firebaseId = user.uid;

        localStorage.setItem('firebaseId', firebaseId);

        dispatch(setUserLoginDetails({ name, email: user.email, photo }));
        closeLogin();
        console.log('Usuário logado com sucesso:');
    } catch (error) {
      setMessage('Erro ao realizar o login do usuario. Tente novamente.');
    }
  };

  const handleRegistro = async () => {
        navigate('/Registro');     
  };

  const handleEsqueci = async () => {
    navigate('/esqueci_minha_senha');     
};

  return (
    <Cont>
      <div className='wrapper'>   
        
        <div className="signup-logo">
          <a href="/home">
            <img src={logo} alt="VidaPet.Tech Logo" />
          </a>
        </div>

        <h1>Olá, Bem-vindo de volta</h1>
<p>Insira suas credenciais para continuar</p>
<br></br>
        <div className='social-icons'>
          <button className='buttonEntrar' onClick={handleGoogleLogin}><FcGoogle size={25} />Entrar com Google</button>
        </div>
<br></br>
        <p>_______________OU_______________</p>

        <form onSubmit={handleLogin}>
          <div className='input-box'>
            <input
              type='text'
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required />
            <FaUser className='icon' />
          </div>

          <div className='input-box'>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='Senha'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <PasswordFaLock showPassword={showPassword} onMouseOver={handleTogglePasswordVisibility} onMouseOut={handleTogglePasswordVisibility} />
            {showPassword ? (
              <PasswordFaEyeSlash showPassword={showPassword} onClick={handleTogglePasswordVisibility} />
            ) : (
              <PasswordFaEye showPassword={showPassword} onClick={handleTogglePasswordVisibility} />
            )}
          </div>
          <button type='submit' className='buttonEntrar'>Entrar</button>
          </form>
<br></br> {message && <p>{message}</p>} <br></br>
          <div className="remember-forgot">
            <button className='buttonRe' onClick={handleEsqueci}>Esqueceu a senha?</button>
          </div>          

        <div className="register-link">
            <p>Ainda não tem uma conta ?  <button className='buttonRe' onClick={handleRegistro}>Cadastre-se</button></p>
          </div>

        {profileInfo && (
          <div className='profile-info'>
            <p>Nome: {profileInfo.name}</p>
            <p>Email: {profileInfo.email}</p>
            <img src={profileInfo.photoURL} alt="Profile" />
          </div>
        )}      

      </div>
    </Cont>
  );
};

export default Login;
