import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaWhatsapp, FaFacebook, FaHeart, FaCommentAlt } from 'react-icons/fa';
import AdoptionFeeds from './AdoptionFeeds';
import './AdoptionDetails.css';

const AdoptionViewDetails = () => {
  const { id } = useParams();
  const [adoption, setAdoption] = useState(null);
  const [userId, setUserId] = useState(null);
  const [followers, setFollowers] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isCommentBoxOpen, setIsCommentBoxOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [refreshFeeds, setRefreshFeeds] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const currentUserId = localStorage.getItem('userId');
    if (currentUserId) {
      setUserId(currentUserId);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      fetchPetDetails();
    }
  }, [userId, id]);

  const fetchPetDetails = () => {
    fetch(`https://vidapet.tech/api/DB/vidapettech/adoption/getadoptioncases.php`)
      .then((response) => response.json())
      .then((data) => {
        const caseData = data.find(adoption => String(adoption.id) === String(id)); // Certificando que ambos os valores são strings para comparação correta
        if (caseData) {
          setAdoption(caseData);
          fetchFollowers(id);
          setIsLoading(false);

          // Console log do link da imagem para verificação
          const petImageLink = caseData.pet_3x4 ? caseData.pet_3x4 : 'default_image_path.jpg';
          console.log('Link da imagem do pet:', petImageLink);
        } else {
          console.error('Caso de adoção não encontrado.');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar os dados da adoção:', error);
        setIsLoading(false);
      });
  };

  const fetchFollowers = (adoptionId) => {
    fetch(`https://vidapet.tech/api/DB/vidapettech/adoption/adoption_follows.php?adoption_id=${adoptionId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setFollowers(data.followers);
          const isUserFollowing = data.followers.includes(parseInt(userId));
          setIsFollowing(isUserFollowing);
        }
      })
      .catch((error) => console.error('Erro ao buscar os seguidores:', error));
  };

  const handleFollowAdoption = () => {
    const action = isFollowing ? 'unfollow' : 'follow';
    const actionUrl = 'https://vidapet.tech/api/DB/vidapettech/adoption/adoption_follows.php';

    const payload = { adoption_id: id, user_id: userId, action };

    fetch(actionUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert(`Ação realizada com sucesso!`);
          setIsFollowing(!isFollowing);
          fetchFollowers(id);
          setRefreshFeeds(!refreshFeeds);
        } else {
          alert(data.message || 'Erro ao realizar a ação.');
        }
      })
      .catch((error) => {
        alert('Erro ao realizar a ação.');
      });
  };

  const handleCommentSubmit = async () => {
    if (!comment.trim()) {
      alert('Por favor, insira um comentário.');
      return;
    }

    const payload = {
      adocao_caso_id: id, // ID do caso de adoção
      sender_id: userId,   // ID do usuário que está comentando
      message: comment,    // O texto do comentário
      action: 'comment',   // Tipo de ação (comentário)
      is_deleted: 'não'    // Marca como não deletado
    };

    console.log('Enviando comentário:', payload);

    try {
      const response = await fetch('https://vidapet.tech/api/DB/vidapettech/adoption/submitComment.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),  // Envia os dados como JSON
      });

      const data = await response.json();  // Recebe a resposta da API

      console.log('Resposta ao enviar comentário:', data);

      if (data.success) {
        alert('Comentário enviado com sucesso!');
        setComment(''); 
        setIsCommentBoxOpen(false);
        setRefreshFeeds(!refreshFeeds); // Atualiza o feed de comentários
      } else {
        alert('Erro ao enviar o comentário.');
      }
    } catch (error) {
      console.error('Erro ao enviar o comentário:', error);
    }
  };

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  if (!adoption) {
    return <div>Erro ao carregar os detalhes do pet para adoção.</div>;
  }

  return (
    <div className="adoption-container">
      <div className="adoption-image-container">
        <img
          src={adoption.pet_3x4 ? adoption.pet_3x4 : 'default_image_path.jpg'} // Garantindo que a imagem seja carregada corretamente
          alt={`Adoção de ${adoption.name}`}
        />
      </div>

      <div className="adoption-details">
        <h2>{adoption.name}</h2>
        <p>Motivo da adoção: {adoption.reason}</p>
        <p>Local: {adoption.city}, {adoption.state}, {adoption.country}</p>
      </div>

      <div className="followers-section">
        <h3>Usuários acompanhando este pet para adoção:</h3>
        <div className="followers-list">
          {followers.length > 0 ? (
            followers.map((followerId) => (
              <div key={followerId}>Usuário ID: {followerId}</div>
            ))
          ) : (
            <p>Nenhum usuário está acompanhando este pet ainda.</p>
          )}
        </div>
      </div>

      <div className="action-buttons">
        <button onClick={handleFollowAdoption}>
          <FaHeart /> {isFollowing ? 'Parar de seguir' : 'Acompanhar'}
        </button>
        <button onClick={() => window.open(`https://api.whatsapp.com/send?text=Confira este pet para adoção! ${window.location.href}`, '_blank')}>
          <FaWhatsapp /> Compartilhar no WhatsApp
        </button>
        <button onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '_blank')}>
          <FaFacebook /> Compartilhar no Facebook
        </button>
      </div>

      <div className="comment-section">
        <button onClick={() => setIsCommentBoxOpen(!isCommentBoxOpen)}>
          <FaCommentAlt /> Comentar sobre esse pet
        </button>

        {isCommentBoxOpen && (
          <div className="comment-box">
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Escreva seu comentário..."
            />
            <button onClick={handleCommentSubmit}>Enviar</button>
          </div>
        )}
      </div>

      {/* Componente de Feeds */}
      <AdoptionFeeds adoptionId={id} refreshFeeds={refreshFeeds} />
    </div>
  );
};

export default AdoptionViewDetails;
