import styled from 'styled-components';

// Container principal
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// Container do mapa
export const MapContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.isExpanded ? '70vh' : '30vh')};
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;

  @media (max-width: 768px) {
    height: ${(props) => (props.isExpanded ? '50vh' : '20vh')};
  }
`;

// Card de resgate e adoção
export const RescueCard = styled.article`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
`;

// Imagem do card
export const RescueImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  cursor: pointer; /* Adicionando cursor pointer para indicar que é clicável */
`;

// Conteúdo do card
export const CardContent = styled.div`
  padding: 15px;
`;

// Título do card
export const CardTitle = styled.h2`
  margin: 0;
  padding-bottom: 10px;
  color: #333;
  font-size: 1.5rem;
  border-bottom: 1px solid #ddd;
`;

// Comentários do card
export const Comments = styled.p`
  color: #555;
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 10px 0;
`;

// Botão de ação
export const ButtonRe = styled.button`
  height: auto;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 18px;
  color: #333;
  font-weight: 700;
  margin: 1rem;

  &:hover {
    background: #00a4e3;
    color: #fff;
  }

  &:active {
    transform: scale(0.9);
  }
`;

// Wrapper para botões de ação
export const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

// Wrapper para botões de compartilhamento
export const ShareButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

// Botão de expandir/reduzir mapa
export const MapExpandButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background-color: #007bff;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: auto;
  background-color: white;
  color: black;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

// Overlay de status de resgate
export const RescueStatusOverlay = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
`;

// Modal de imagem
export const ImageModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Imagem do modal
export const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;

// Componente para botão de filtro
export const FilterButton = styled.button`
  margin: 15px 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;
// Componente para botão de filtro1
export const FilterButton1 = styled.button`
  margin: 15px 0;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;
// Contêiner do Filtro
export const FilterContainer = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

// Substitua `PetActionButton` por `ButtonRe` no `FollowButton`
export const FollowButton = styled(ButtonRe)`
  width: 100%;
  height: 45px;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 700;

  &:active {
    transform: scale(0.9);
  }
`;

// Container para colunas de resgate e adoção
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column; /* Modo Mobile: exibir em coluna */
  gap: 20px;
  margin-top: 20px;

  @media (min-width: 1024px) {
    flex-direction: row; /* Modo Desktop: exibir em linha */
  }
`;

// Coluna de resgate ou adoção
export const Column = styled.div`
  flex: ${(props) => (props.larger ? '3' : '1')}; /* Definindo a largura das colunas */
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  width: 100%; /* Garantindo que as colunas ocupem a largura completa no modo mobile */

  @media (min-width: 1024px) {
    width: ${(props) => (props.larger ? '75%' : '25%')}; /* Largura das colunas no modo desktop */
  }
`;

// Lista de resgates e adoções com layout responsivo
export const RescueList = styled.section`
  display: grid;
  grid-template-columns: 1fr; /* Modo Mobile: 1 coluna */
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Modo Tablet: 2 colunas */
  }

  @media (min-width: 1024px) {
    grid-template-columns: ${(props) => (props.isAdoptionVisible ? '1fr' : 'repeat(3, 1fr)')}; /* Modo Desktop: 3 colunas para resgate, 1 coluna para adoção */
    max-width: ${(props) => (props.isAdoptionVisible ? '400px' : '1200px')}; /* Ajustando largura máxima da lista de adoção */
  }
`;

