import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth } from '../firebase';
import {
  selectUserName,
  selectUserPhoto,
  setUserLoginDetails,
  setSignOutState,
} from '../pages/Login/UserInfo';
import Login from '../pages/Login/Login';
import Registro from '../pages/Login/Cadastro/SignUp';
import logo from '../imagens/logoOficial2024.png';

const PageHeaders = () => {
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [isRegistroVisible, setIsRegistroVisible] = useState(false);
  const [isEsqueciSenhaVisible, setIsEsqueciSenhaVisible] = useState(false);
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  
  const menuRef = useRef(null); // Criar uma referência para o menu

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector(selectUserName);
  const userPhoto = useSelector(selectUserPhoto);

  const setUser = (user) => {
    dispatch(
      setUserLoginDetails({
        name: user.displayName,
        email: user.email,
        photo: user.photoURL,
      })
    );
  };

  const syncProfileWithBackend = (profileInfo) => {
    const backendUrl = 'https://vidapet.tech/sync-user.php';

    axios.post(backendUrl, profileInfo)
      .then(response => {
        console.log('Perfil sincronizado com o backend:', response.data);
        localStorage.setItem('firebaseId', profileInfo.firebaseId);
      })
      .catch(error => {
        console.error('Erro ao sincronizar com o backend:', error);
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);

        const profileInfo = {
          firebaseId: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        };

        syncProfileWithBackend(profileInfo);
      } else {
        dispatch(setSignOutState());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  // Fechar o menu ao clicar fora dele
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const openLoginMenu = () => {
    setIsLoginVisible(true);
    setIsRegistroVisible(false);
    setIsEsqueciSenhaVisible(false);
    setMenuIsVisible(true);
  };

  const closeLogin = () => {
    setIsLoginVisible(false);
    setMenuIsVisible(false);
  };


  const opeRegistro = () => {
    navigate('/registro');
  };

  const openRegistro = () => {
    setIsRegistroVisible(true);
    setIsLoginVisible(false);
    setIsEsqueciSenhaVisible(false);
    setMenuIsVisible(true);
  };

  const closeRegistro = () => {
    setIsRegistroVisible(false);
    setIsLoginVisible(true);
  };

  const openEsqueciSenha = () => {
    setIsEsqueciSenhaVisible(true);
    setIsLoginVisible(false);
    setIsRegistroVisible(false);
  };

  const closeEsqueciSenha = () => {
    setIsEsqueciSenhaVisible(false);
    setIsLoginVisible(true);
  };

  const handleSignOut = () => {
    auth.signOut()
      .then(() => {
        dispatch(setSignOutState());
        localStorage.removeItem('firebaseId');
        setMenuIsVisible(false);
        navigate('/home');
      })
      .catch((err) => alert(err.message));
  };

  return (
    <Container>
      <LeftSection>
        <Logo>
          <a href="/home">
            <img src={logo} alt="VidaPet.Tech Logo" />
          </a>
        </Logo>
        <NavMenu>
          <a href="/dashboard">
            <span>Gestão de Pets</span>
          </a>         
          <a href="/Resgate">
            <span>Resgate</span>
          </a>
          <a href="/servicos">
            <span>Serviços</span>
          </a>         
          <a href="/Blog">
            <span>Blogs & Noticias</span>
          </a>
        </NavMenu>
      </LeftSection>
      
      <RightSection>
        {!userName ? (       
          <div>
               
       {/* <button onClick={opeRegistro} className='button1'>Cadastro / Login</button> */}

        <button onClick={openLoginMenu} className='button1'>Cadastro  Login</button>
        </div>
          

        ) : (
          <SignOut onClick={() => setMenuIsVisible(!menuIsVisible)}>
            <UserImgContainer>
              <UserImg src={userPhoto} alt={userName} />
              <UserRing />
            </UserImgContainer>
          </SignOut>
        )}
      </RightSection>

      {menuIsVisible && (
        <UserMenu ref={menuRef}>
          {!userName ? (
            <>
              {isLoginVisible && (
                <Login
                  closeLogin={closeLogin}
                  openRegistro={openRegistro}
                  openEsqueciSenha={openEsqueciSenha}
                />
              )}             
              
 <Divider /> 
              <MenuItem onClick={() => { 
                navigate('/dashboard');
                setMenuIsVisible(false);
              }}>Gestão de Pets</MenuItem>
              
              <MenuItem onClick={() => { 
                navigate('/Resgate');
                setMenuIsVisible(false);
              }}>Resgate</MenuItem>

              <MenuItem onClick={() => { 
                navigate('/servicos');
                setMenuIsVisible(false);
              }}>Serviços</MenuItem>

              <Divider />



            </>
          ) : (
            <>
              <UserInfoSection>
                <UserImgContainerLarge>
                  <UserImg src={userPhoto} alt={userName} />
                </UserImgContainerLarge>
                <UserName>{userName}</UserName>
              </UserInfoSection>

              <Divider /> 
              <MenuItem onClick={() => { 
                navigate('/dashboard');
                setMenuIsVisible(false);
              }}>Gestão de Pets</MenuItem>             

              <MenuItem onClick={() => { 
                navigate('/Resgate');
                setMenuIsVisible(false);
              }}>Resgate</MenuItem>

              <MenuItem onClick={() => { 
                navigate('/servico');
                setMenuIsVisible(false);
              }}>Marketplace</MenuItem>

<MenuItem onClick={() => { 
                navigate('/dashboard');
                setMenuIsVisible(false);
              }}>Meu Perfil</MenuItem>

              <Divider />
              <MenuItem onClick={handleSignOut}>Sair</MenuItem>
            </>
          )}
        </UserMenu>
      )}
    </Container>
  );
};

// CSS Styles para PageHeaders.js
const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  background: #4A90E2;
  color: #ffffff;
  box-sizing: border-box;
  height: 60px;

  /* Verifique se o botão não está sendo coberto */
  .button1 {
    background: #fff;
    border:none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size:16px;
    color: #333;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
    margin: 8px;
    padding: 10px 10px;
    width: 150px;
    z-index: 10; /* Certifique-se de que o botão fique visível em cima dos outros componentes */

    &:active {
      transform: scale(0.9);
    }

    @media(max-width: 500px) {
      padding: 10px 4px;
      width: 100px;
      margin: 2px;
      font-size:13px;
    }
  }

  /* Verifique a altura e margens de outros containers */
  .login-container {
    position: absolute;
    top: 100px; /* Ajuste conforme necessário para garantir que não sobreponha outros elementos */
    left: 0;
    right: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5); /* Transparente */
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;


const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  padding: 0;
  margin-top: 2px;
  max-height: 86px;
  display: inline-block;

  img {
    max-height: 60px;
    width: auto;
  }
`;

const NavMenu = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-left: 25px;  

  a {
    display: flex;
    align-items: center;
    padding: 0 12px;
    text-decoration: none;

    span {
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.42px;
      line-height: 1.08;
      padding: 2px 0px;
      white-space: nowrap;
      position: relative;

      &:before {
        background-color: #AED9E0;
        border-radius: 0px 0px 4px 4px;
        bottom: -6px;
        content: "";
        height: 2px;
        left: 0px;
        opacity: 0;
        position: absolute;
        right: 0px;
        transform-origin: left center;
        transform: scaleX(0);
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
        visibility: hidden;
        width: auto;
      }
    }

    &:hover {
      span:before {
        transform: scaleX(1);
        visibility: visible;
        opacity: 1 !important;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
    
`;

const InicioIcon = styled.div`
  margin-right: 16px;
  cursor: pointer;
  color: #FF7F50;
`;

const UserImgContainer = styled.div`
  position: relative;
  height: 48px;
  width: 48px;
`;

const UserImg = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const UserRing = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 101%;
  width: 101%;
  padding: 8px;
  box-sizing: border-box;
  border: 3px solid #FF7F50;
  border-radius: 50%;
  pointer-events: none;
`;

const SignOut = styled.div`
  position: relative;
  height: 48px;
  width: 48px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const UserMenu = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  width: 100%; /* Ajustar para ocupar a largura da tela em dispositivos menores */
  max-width: 300px; /* Definir um limite máximo */
  height: auto;
  max-height: 50vh;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 8px 0 0 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1020;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  transition: transform 0.3s ease;

  @media (max-width: 768px) {
    right: 0;
    width: 90%;  
  }
`;

const UserInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const UserImgContainerLarge = styled.div`
  height: 60px;
  width: 60px;
  margin-bottom: 10px;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const UserName = styled.h3`
  margin: 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #555;
  margin: 10px 0;
`;

const MenuItem = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #555;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const LoginHint = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #FF7F50;
`;

const ArrowIcon = styled.div`
  margin-right: 8px;
`;

const HintText = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin-right: 12px;
`;

export default PageHeaders;
