import React from 'react';
import { useParams } from 'react-router-dom';
import './BlogPostDetail.css';

const blogPosts = [
  // Sua lista de posts aqui
  {
    id: 1,
    title: 'VidaPet.Tech é Selecionada para o Programa de Aceleração do Ibmec Hubs Rio de Janeiro',
    date: '30/09/2024',
    introduction: 'Com grande entusiasmo, anunciamos que a VidaPet.Tech foi escolhida para integrar o renomado programa de aceleração do Ibmec Hubs Rio de Janeiro, um dos principais centros de inovação do Brasil.',
    body1: 'Esse marco valida o esforço da nossa equipe e evidencia o impacto significativo que nossa plataforma vem gerando no setor de tecnologia voltada à saúde animal. Nossa missão é clara: melhorar a qualidade de vida dos pets e facilitar a gestão de saúde para seus tutores e profissionais da área.',
    body2: 'Através da tecnologia, buscamos simplificar o acesso a informações essenciais sobre vacinas, check-ups, exames e tratamentos, garantindo que os animais de estimação estejam sempre saudáveis. A participação no programa de aceleração oferece uma oportunidade valiosa para impulsionar nossa proposta.',
    body3: 'Nos próximos meses, teremos acesso a mentorias de alto nível e suporte em áreas estratégicas, além de uma rede de contatos que poderá abrir portas para novas parcerias e investimentos. Estamos ansiosos para aproveitar ao máximo essa oportunidade e escalar a VidaPet.Tech para novos patamares.',
    conclusion: 'Agradecemos a todos os nossos parceiros, investidores e, especialmente, aos nossos usuários, que confiam e continuam a acreditar em nossa proposta. Juntos, vamos transformar a maneira como a saúde pet é gerida no Brasil e no mundo.',    
    imageUrl: 'https://midias.jb.com.br/_midias/jpg/2021/04/07/ibmec-592121.jpg',
    tags: ['Vida Pet', 'Novidade'],
    authorImage: 'https://vidapet.tech/static/media/VidaPetTechLogo2024Completa01.0bc38550427b686f8661.png',
    author: 'Por Equipe VidaPet.Tech',
    featuredPosts: [
      { title: 'VidaPet.Tech entra para o programa de aceleração do Ibmec Hubs Rio de Janeiro', date: '30/09/2024', imageUrl: 'https://blog.ibmec.br/wp-content/uploads/2021/03/Logo-Nova-Marca-Ibmec_Hubs-1024x783.png' },
      { title: 'Quantas vacinas um cão precisa tomar durante sua vida?', date: '14/09/2024', imageUrl: 'https://labovet.com.br/wp-content/uploads/2019/03/vacina_caes_widexl.png' },
      // Adicione mais posts relacionados conforme necessário
    ],
  },
];

const BlogPostDetail = () => {
  const { id } = useParams();
  const post = blogPosts.find((p) => p.id === parseInt(id));

  if (!post) {
    return <p>Post not found.</p>;
  }

  return (
    <div className="blog-post-detail">
      <h1>{post.title}</h1>
      <img src={post.imageUrl} alt={post.title} className="large-image" />
      
      <div className="content-wrapper">
        <div className="main-content1">
          <div className="post-content">
            <h2>Introdução</h2>
            <p>{post.introduction}</p>
            <h2>A Necessidade de Transformação Digital</h2>
            <p>{post.body1}</p>
            <h2>Implementando Soluções Digitais</h2>
            <p>{post.body2}</p>
            <h2>Casos de Sucesso</h2>
            <p>{post.body3}</p>
            <h2>Conclusão</h2>
            <p>{post.conclusion}</p>
            <div className="tags">
              {post.tags.map((tag, index) => (
                <span key={index} className="tag">{tag}</span>
              ))}
            </div>
          </div>
        </div>
        
        <div className="related-posts">
          <h2>Posts Relacionados</h2>
          {post.featuredPosts.map((featuredPost, index) => (
            <div key={index} className="featured-post1">
              <h3>{featuredPost.title}</h3>
              <p>{featuredPost.date}</p>
              <img src={featuredPost.imageUrl} alt={featuredPost.title} />
            </div>
          ))}
        </div>
      </div>

      <div className="email-signup">
        <h2>Inscreva-se para mais atualizações</h2>
        <input type="email" placeholder="Digite seu email" />
        <button>Inscrever-se</button>
      </div>

    </div>

  );
};

export default BlogPostDetail;

