import React, { useState } from 'react';
import SearchInput from './SearchInput';

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchSearchResults = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await fetch(`https://vidapet.tech/MARKET/API/search_lojas.php?search=${searchTerm}`);
      const text = await response.text();  // Obter a resposta como texto
      try {
        const data = JSON.parse(text);  // Tentar analisar o texto como JSON
        if (Array.isArray(data)) {
          setResults(data);
        } else {
          setResults([]);
        }
      } catch (jsonError) {
        throw new Error('Resposta não é um JSON válido.');
      }
    } catch (error) {
      console.error('Erro ao buscar resultados:', error);
      setError('Erro ao buscar resultados. Verifique o console para mais detalhes.');
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      setResults([]);
      return;
    }
    fetchSearchResults();
  };

  return (
    <div>
      <SearchInput
        value={searchTerm}
        onChange={handleSearchChange}
        onSearch={handleSearch}
        placeholder="Pesquisar produtos ou lojas..."
      />
      {isLoading ? (
        <p>Carregando...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          {results.length > 0 ? (
            results.map((item) => (
              <div key={item.id}>
                <h3>{item.type === 'produto' ? 'Produto' : 'Loja'}: {item.name}</h3>
                <p>{item.description}</p>
                {item.price && <p>Preço: {item.price}</p>}
              </div>
            ))
          ) : (
            <p>Nenhum resultado encontrado.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
