import React, { useRef, useState, useCallback, useEffect } from 'react';
import HeartExplosion from './HeartExplosion';
import PetLostForm from './PetLostForm';
import './PetLostForm.css';
import { WebcamStyled, ButtonsContainer, PreviewContainer, CloseButton, ButtonCamera, ButtonMap } from './CSS/styleCamera';

function WebcamCapture({ closeWebcam, onCaptureImage }) {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [step, setStep] = useState(1);
  const [comment, setComment] = useState('');
  const [showHeartExplosion, setShowHeartExplosion] = useState(false);
  const [useMobileUpload, setUseMobileUpload] = useState(false);

  // Adiciona latitude e longitude
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [captureDatetime, setCaptureDatetime] = useState(null);
  const [petType, setPetType] = useState('');

  useEffect(() => {
    const currentDate = new Date();
    setCaptureDatetime(currentDate.toISOString());

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error('Erro ao obter a localização:', error);
        }
      );
    }
  }, []);

  const captureImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setShowPreview(true);
  }, []);

  const closeCameraModal = () => {
    closeWebcam();
  };

  const handleCancelPreview = () => {
    setCapturedImage(null);
    setShowPreview(false);
  };

  const handleConfirmPreview = () => {
    setShowPreview(false);
    setStep(3);
  };

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length; // Use `let` aqui
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append('latitude', latitude);
      formData.append('longitude', longitude);
      formData.append('capture_datetime', captureDatetime);
      formData.append('comments', comment);
      formData.append('pet_type', petType);
      formData.append('capturedImage', dataURLtoFile(capturedImage, 'captured.jpg'));
  
      console.log('Dados enviados:');      
  
      const response = await fetch('https://vidapet.tech/upload.php', {
        method: 'POST',
        body: formData,
      });
  
      const responseData = await response.text(); // ou `response.json()` se for JSON
      console.log('Resposta do servidor:', responseData);
  
      if (response.ok) {
        console.log('Dados enviados com sucesso!');
        setShowHeartExplosion(true);
      } else {
        console.error('Erro ao enviar dados:', responseData);
      }
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
    }
  };
  
  const handleAnimationComplete = () => {
    setShowHeartExplosion(false);
    closeWebcam();
  };

  if (useMobileUpload) {
    return <PetLostForm closeForm={closeWebcam} />;
  }

  return (
    <div className="modalCamera">
      {showHeartExplosion && (
        <div className="explosion-container">
          <HeartExplosion onAnimationComplete={handleAnimationComplete} />
        </div>
      )}

      {showPreview ? (
        <PreviewContainer show={showPreview}>
          <img src={capturedImage} alt="Imagem capturada" />
          <div>
            <button onClick={handleCancelPreview}>Tirar outra</button>
            <button onClick={handleConfirmPreview}>Confirmar</button>
          </div>
        </PreviewContainer>
      ) : (
        <>
          {step === 1 && (
            <div className="step step-intro">
              <h2 style={{ marginBottom: '20px' }}>Como deseja prosseguir?</h2>
              <p style={{ marginBottom: '30px', textAlign: 'center' }}>Escolha uma das opções abaixo para enviar uma foto do pet encontrado ou perdido:</p>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                <button
                  onClick={() => setStep(2)}
                  style={{
                    width: '90%',
                    maxWidth: '400px',
                    padding: '15px',
                    fontSize: '18px',
                    backgroundColor: '#ff9800',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '8px',
                    textAlign: 'center',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                  }}
                >
                  <strong>Pet Perdido/Abandonado!</strong>
                  <p style={{ fontSize: '14px', margin: '10px 0 0' }}>Capture a Foto de um Pet Perdido ou Abandonado por aqui para gerar um alerta para alguem ajudar.</p>
                </button>
                <button
                  onClick={() => setUseMobileUpload(true)}
                  style={{
                    width: '90%',
                    maxWidth: '400px',
                    padding: '15px',
                    fontSize: '18px',
                    backgroundColor: '#ff3d00',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '8px',
                    textAlign: 'center',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                  }}
                >
                  <strong>Meu Pet Está Perdido!</strong>
                  <p style={{ fontSize: '14px', margin: '10px 0 0' }}>Carregue uma foto do seu pet perdido diretamente do seu dispositivo para gerar um Alerta de Procura.</p>
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <>
              <WebcamStyled
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode: 'environment' }} // ou 'user'
              />
              <ButtonsContainer>
                <ButtonCamera onClick={captureImage} />
              </ButtonsContainer>
              <CloseButton size={30} onClick={closeCameraModal} />
            </>
          )}

          {step === 3 && (
            <div className="step step-comments">
              <h2>Compartilhe Informações Sobre o Pet Encontrado</h2>
              <p>
                Encontrar um pet perdido é um grande ato de bondade! Para ajudar na identificação e na busca pelo dono, por favor, forneça o máximo de detalhes possível sobre o animal que você encontrou. Quanto mais informações você compartilhar, melhor será a chance de reunirmos o pet com sua família.
              </p>
              <div>
                <label htmlFor="petType">Tipo de Pet:</label>
                <select
                  id="petType"
                  value={petType}
                  onChange={(e) => setPetType(e.target.value)}
                  style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
                >
                  <option value="">Selecione o tipo de pet</option>
                  <option value="cachorro">Cachorro</option>
                  <option value="gato">Gato</option>
                  <option value="outros">Outros</option>
                </select>
              </div>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Descreva o pet encontrado aqui..."
                rows={6}
                style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
              />
              <div className="navigation-buttons">
                <ButtonMap onClick={() => setStep(2)}>Voltar</ButtonMap>
                <ButtonMap onClick={handleSave}>Salvar e Enviar</ButtonMap>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default WebcamCapture;
