import React, { useState, useEffect } from 'react';
import { FaCamera, FaMapMarkerAlt, FaUpload, FaInfoCircle, FaShareAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import WebcamCapture from './Components/camera';
import RescueView from '../Resgate/RescueView';
import AdoptionView from './AdoptionView';  // Importando o componente de adoção
import PetLostForm from './Components/PetLostForm';
import QrCode from './QrCode/QrCode';
import { BsQrCodeScan } from "react-icons/bs";

function RescueDashboard() {
  const [activeComponent, setActiveComponent] = useState('rescueCases');
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const petId = queryParams.get('qrcode');

  useEffect(() => {
    if (petId) {
      setActiveComponent('qrCode');
    }
  }, [petId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const renderContent = () => {
    switch (activeComponent) {
      case 'rescueCases':
        return <RescueView />; // Exibe os casos de resgate
      case 'adoptionCases':
        return <AdoptionView />; // Exibe os casos de adoção
      case 'qrCode':
        return <QrCode petId={petId} />;
      case 'webcam':
        return <WebcamCapture closeWebcam={() => setActiveComponent('rescueCases')} />;
      case 'petLostForm':
        return <PetLostForm />;
      default:
        return <RescueView />;
    }
  };

  return (
    <DashboardContainer>
      <Navbar>
        <SidebarButton onClick={() => setActiveComponent('webcam')}>
          <IconWrapper><FaCamera /></IconWrapper> Alerta Pet
        </SidebarButton>

        <SidebarButton1 onClick={() => setActiveComponent('qrCode')}>
          <IconWrapper><BsQrCodeScan /></IconWrapper> QR Code
        </SidebarButton1>

        {/* Botão para alternar entre Resgate e Adoção */}
        <SidebarButton1 onClick={() => setActiveComponent(activeComponent === 'rescueCases' ? 'adoptionCases' : 'rescueCases')}>
          <IconWrapper><FaMapMarkerAlt /></IconWrapper>
          {activeComponent === 'rescueCases' ? 'Adote' : 'Resgate'}
        </SidebarButton1>
      </Navbar>

      <ContentContainer>
        {renderContent()}
      </ContentContainer>

      {showPopup && (
        <MobilePopup>
          <h4>🛑 Atenção! 🛑</h4>
          <ol>
            <StepItem>
              <FaCamera className="photo-icon" />
              <li>Capture ou Suba uma Foto do Pet no botão "Alerta Pet."</li>
            </StepItem>
            <StepItem>
              <FaMapMarkerAlt className="location-icon" />
              <li>Pelo VidaPet.Tech a foto sobe com Geolocalização e mais</li>
            </StepItem>
            <StepItem>
              <FaUpload className="upload-icon" />
              <li>Para Nosso Mapa Interativo de Resgate.</li>
            </StepItem>
            <StepItem>
              <FaInfoCircle className="info-icon" />
              <li>O Alerta/Caso de Resgate contém as informações adicionais que você incluir.</li>
            </StepItem>
            <StepItem>
              <FaShareAlt className="share-icon" />
              <li>Você pode Compartilhar o Caso Registrado nas suas Redes Sociais!</li>
            </StepItem>
          </ol>
          <CloseButton onClick={() => setShowPopup(false)}>Entendi</CloseButton>
        </MobilePopup>
      )}
    </DashboardContainer>
  );
}

// Animação para bordas piscantes
const blinkingBorder = keyframes`
  0% {
    border: 2px solid #ff6f61;
  }
  50% {
    border: 2px solid #fff;
  }
  100% {
    border: 2px solid #ff6f61;
  }
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f7f9fc;  
`;

const Navbar = styled.div`
  display: flex;
  background-color: #e3f2fd;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const SidebarButton = styled.button`
  background: #ff9800; /* Fundo laranja */
  color: #ffffff;
  height: 60px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;

  &:hover {
    background: #fb8c00; /* Laranja mais escuro ao passar o mouse */
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const SidebarButton1 = styled.button`
  background: #1e88e5; /* Fundo azul */
  color: #ffffff;
  height: 60px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;

  &:hover {
    background: #fb8c00; /* Azul mais escuro ao passar o mouse */
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1; 
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;

  @media (max-width: 750px) {
    padding-bottom: 60px; 
  }
`;

// Popup no mobile
const MobilePopup = styled.div`
  position: fixed;
  bottom: 100px;
  left: 40%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #333;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 90%;
  z-index: 3000;

  h4 {
    color: #ff9800; /* Cor chamativa para o título */
  }

  ol {
    margin: 10px 0;
  }

  /* Cor diferente para cada item */
  .photo-icon {
    color: #ff9800;
  }

  .location-icon {
    color: #1e88e5;
  }

  .upload-icon {
    color: #43a047;
  }

  .info-icon {
    color: #f44336;
  }

  .share-icon {
    color: #ab47bc;
  }
`;

const StepItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  li {
    margin-left: 15px; 
    list-style: none; 
    font-size: 0.85em;
  }

  svg {
    font-size: 2rem;
    min-width: 30px;
  }
`;

const CloseButton = styled.button`
  background-color: #1e88e5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #1e88e5;
  }
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 20px;
`;

export default RescueDashboard;
