// src/pages/LoginAppPage.js
import React from 'react';
import Login from './Login';  // Ajuste o caminho conforme necessário

const LoginAppPage = () => {
  return (
    <div>
      <Login closeLogin={() => {}} /> {/* Passa a função closeLogin caso necessário */}
    </div>
  );
}

export default LoginAppPage;
