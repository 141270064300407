import React, { useEffect, useState } from 'react';
import {
  ColumnContainer,
  Column,
  RescueCard,
  RescueImage,
  CardContent,
  CardTitle,
  Comments,
  ButtonRe,
  ActionButtonWrapper,
} from './Components/CSS/styleRescue';
import { FaArrowCircleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const AdoptionView = () => {
  const [adoptionData, setAdoptionData] = useState([]);
  const [filteredAdoptionData, setFilteredAdoptionData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Buscar dados de adoção
    fetch('https://vidapet.tech/api/DB/vidapettech/adoption/getadoptioncases.php')
      .then((response) => response.json())
      .then((data) => {
        // Log dos dados recebidos
        console.log('Dados recebidos da tabela:', data);

        // Filtrar os casos de adoção com estado e cidade válidos
        const adoptionCases = data.filter(
          (item) => item.state && item.city && item.state.trim() !== "" && item.city.trim() !== ""
        );

        // Log dos casos de adoção filtrados
        console.log('Casos de adoção filtrados:', adoptionCases);

        setAdoptionData(adoptionCases);
        setFilteredAdoptionData(adoptionCases); // Exibir todos os casos de adoção por padrão

        // Extrair e mapear os estados únicos válidos
        const uniqueStates = [...new Set(adoptionCases.map((item) => item.state).filter(Boolean))];
        setStates(uniqueStates);

        // Log para estados e cidades capturados
        console.log("Estados capturados:", uniqueStates);
        console.log("Cidades capturadas:", [...new Set(adoptionCases.map((item) => item.city).filter(Boolean))]);
      })
      .catch((error) => console.error('Erro ao buscar dados de adoção:', error));
  }, []);

  // Função para carregar cidades com base no estado selecionado
  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity('');

    // Filtrar e definir cidades com base no estado selecionado
    if (state) {
      const citiesInState = adoptionData
        .filter((item) => item.state.toLowerCase() === state.toLowerCase())
        .map((item) => item.city);
      setCities([...new Set(citiesInState.filter(Boolean))]); // Cidades únicas no estado selecionado
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  useEffect(() => {
    // Filtrar casos de adoção com base no estado e cidade
    if (selectedCity && selectedState) {
      const filteredData = adoptionData.filter(
        (adoption) =>
          adoption.state.toLowerCase() === selectedState.toLowerCase() &&
          adoption.city.toLowerCase() === selectedCity.toLowerCase()
      );
      setFilteredAdoptionData(filteredData);
    } else if (selectedState) {
      // Filtrar apenas por estado
      const filteredData = adoptionData.filter(
        (adoption) => adoption.state.toLowerCase() === selectedState.toLowerCase()
      );
      setFilteredAdoptionData(filteredData);
    } else {
      setFilteredAdoptionData(adoptionData); // Mostrar todos os casos se não houver filtro
    }
  }, [selectedCity, selectedState, adoptionData]);

  const handleFollow = (id) => {
    navigate(`/adoptions/${id}`);
  };

  return (
    <ColumnContainer>
      {/* Filtro de Estado e Cidade */}
      <div>
        <label>Estado:</label>
        <select value={selectedState} onChange={handleStateChange}>
          <option value="">Selecione um estado</option>
          {states.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>

        <label>Cidade:</label>
        <select value={selectedCity} onChange={handleCityChange} disabled={!selectedState}>
          <option value="">Selecione uma cidade</option>
          {cities.map((city, index) => (
            <option key={index} value={city}>
              {city}
            </option>
          ))}
        </select>
      </div>

      {/* Exibição dos casos de adoção */}
      {filteredAdoptionData.length > 0 ? (
        filteredAdoptionData.map((adoption) => (
          <Column key={adoption.id}>
            <RescueCard>
            <RescueImage
  src={adoption.pet_3x4 ? adoption.pet_3x4 : 'default_image_path.jpg'} // Usando diretamente o caminho retornado
  alt={`Adoção ${adoption.title}`}
/>


              <CardContent>
                <CardTitle>{adoption.title}</CardTitle>
                <Comments>{adoption.comments}</Comments>
                <ActionButtonWrapper>
                  <ButtonRe onClick={() => handleFollow(adoption.id)}>
                    Acompanhar Adoção <FaArrowCircleRight />
                  </ButtonRe>
                </ActionButtonWrapper>
              </CardContent>
            </RescueCard>
          </Column>
        ))
      ) : (
        <div>Nenhuma adoção disponível nesta área.</div>
      )}
    </ColumnContainer>
  );
};

export default AdoptionView;
