import React, { useEffect, useState } from 'react';
import './Cartalogo.css'; // Importando o CSS


const Gallery = ({ serviceId }) => {
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        const fetchPhotos = async () => {
            if (!serviceId) return;
    
            try {
                const response = await fetch(`http://vidapet.tech/service_fotos.php?service_id=${serviceId}`);
                console.log('Resposta da API:', response);
    
                if (!response.ok) {
                    throw new Error('Erro na resposta da API');
                }
    
                const data = await response.json();
                console.log('Dados da API:', data);
    
                // Ajuste conforme a estrutura da resposta
                if (Array.isArray(data.photos)) {
                    setPhotos(data.photos);
                } else {
                    console.error('Formato inesperado dos dados:', data);
                }
            } catch (error) {
                console.error('Erro ao buscar fotos:', error);
            }
        };
    
        fetchPhotos();
    }, [serviceId]);
    

    return (
        <div className="gallery">
            {photos.length > 0 ? (
                photos.map((photo, index) => (
                    <div className="gallery-item" key={index}>
                        <img 
    src={photo} 
    alt={`Foto ${index + 1}`} 
    onError={(e) => { e.target.src = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHv1AYr_hezxUTbGTBuemWIBTkr7dRvU2mBw&s'; }} 
/>
                    </div>
                ))
            ) : (
                <p>Nenhuma foto encontrada.</p>
            )}
        </div>
    );
};

export default Gallery;
