// Components/Filtro.js
import React from 'react';

const Filtro = ({ searchTerm, setSearchTerm, bairro, setBairro, categoria, setcategoria }) => {
  return (
            <div className="filter-container">                        
      <input 
        type="text" 
        placeholder="Pesquisar parceiros" 
        value={searchTerm} 
        onChange={e => setSearchTerm(e.target.value)} 
      />
      <input 
        type="text" 
        placeholder="Bairro" 
        value={bairro} 
        onChange={e => setBairro(e.target.value)} 
      />
      <select value={categoria} onChange={e => setcategoria(e.target.value)}>
        <option value="">Selecione um tipo de serviço</option>
        <option value="1">Clínica Veterinária</option>
        <option value="2">Taxi Dog</option>
        <option value="3">Veterinário</option>
        <option value="4">Adestradores</option>
        <option value="5">Andador</option>
        <option value="6">Banho e Tosa</option>
      </select>
    </div>
  );
};

export default Filtro;
