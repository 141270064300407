import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import racaoImg from '../../../../imagens/OCA.png';
import banhoETosaImg from '../../../../imagens/MascoteLiliVidaPetTech.png';
import petTaxiImg from '../../../../imagens/Prefeitura.png';
import veterinariosImg from '../../../../imagens/SobreNos01.png';

// Simulando dados da API para o exemplo
const api = {
  get: async (endpoint) => {
    // Simula uma chamada de API
    return {
      data: [
        { id: 1, categorie_url: racaoImg, title: 'Lojas de Ração' },
        { id: 2, categorie_url: banhoETosaImg, title: 'Banho e Tosa' },
        { id: 3, categorie_url: petTaxiImg, title: 'Pet Taxi' },
        { id: 4, categorie_url: veterinariosImg, title: 'Veterinários' }     
      ]
    };
  }
};

function Categories() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function loadCategories() {
      // Obter dados da rota 'categories' da api fake
      const response = await api.get('categories');
      setCategories(response.data);
    }
    loadCategories();
  }, []);

  return (
    <Container>
      <Header>
        <Title>Categorias</Title>
      </Header>

      <CategoriesList>
        {/* Mapear categorias para cada item */}
        {categories.map(item => (
          <Item key={item.id}>
            <ItemImage src={item.categorie_url} alt={item.title} />
            <ItemTitle>{item.title}</ItemTitle>
          </Item>
        ))}
      </CategoriesList>
    </Container>
  );
}



// Estilos com styled-components para web
const Container = styled.div`
  margin: 30px 0;
`;

const Header = styled.div`
  margin-left: 20px;
`;

const Title = styled.h2`
  font-size: 23px;
  font-weight: bold;
`;

const CategoriesList = styled.div`
  display: flex;
  overflow-x: auto; /* Scroll horizontal se necessário */
  margin-top: 10px;
  padding-left: 20px;
`;

const Item = styled.div`
  margin-right: 4rem;
  display: flex;
  flex-direction: column; /* Alinha itens verticalmente */
  align-items: center; /* Alinha itens horizontalmente */
  text-align: center; /* Centraliza o texto dentro do Item */
`;

const ItemImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 10px;
`;

const ItemTitle = styled.p`
  font-size: 16px;
  margin-top: 10px;
  color: #999;
`;


export default Categories;
