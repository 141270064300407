import React from 'react';
import BlogList from './BlogList';
import BlogCategory from './BlogCategory';

const BlogPage = () => {
  return (
    <div>
      <BlogList />
    </div>
  );
};

export default BlogPage;
