import React from 'react';
import petListImg from '../../imagens/petlist.png'; // Substitua com o caminho correto da imagem
import carteirinhaImg from '../../imagens/carteirinha1.png'; // Substitua com o caminho correto da imagem
import vacinacoesImg from '../../imagens/vacinacoes1.png'; // Substitua com o caminho correto da imagem
import consultasImg from '../../imagens/consultas1.png'; // Substitua com o caminho correto da imagem
import examesImg from '../../imagens/exames1.png'; // Substitua com o caminho correto da imagem
import qrCodeImg from '../../imagens/qrcode1.png'; // Substitua com o caminho correto da imagem

const DashboardHome = ({ onAccess }) => {
  return (
    <div style={styles.dashboardHome}>
      <p style={styles.paragraph}>
        Cadastre-se Grátis para Gerenciar todas as informações de saúde dos seus pets de forma prática e organizada. Abaixo estão as funcionalidades que você pode acessar:
      </p>

      <div style={styles.infoSection}>
        {infoItems.map((item, index) => (
          <div key={index} style={styles.infoItem}>
            <div style={styles.imageContainer}>
              <img src={item.img} alt={item.alt} style={styles.infoImage} />
              <div style={styles.descriptionOverlay} onClick={() => onAccess(item.title)}>
                <p style={styles.descriptionText}><strong>{item.title}</strong></p>
                <p style={styles.descriptionText}>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const infoItems = [
  {
    img: petListImg,
    alt: "Lista de Pets",
    description: "Visualize e gerencie todos os seus pets cadastrados.",
  },
  {
    img: carteirinhaImg,
    alt: "Carteirinha Digital",
    description: "Crie uma carteirinha digital com todas as informações essenciais do seu pet.",
  },
  {
    img: vacinacoesImg,
    alt: "Vacinações",
    description: "Registre e acompanhe o histórico de vacinações dos seus pets.",
  },
  {
    img: consultasImg,
    alt: "Consultas",
    description: "Agende e registre consultas veterinárias.",
  },
  {
    img: examesImg,
    alt: "Exames",
    description: "Acompanhe e registre todos os exames realizados pelos seus pets.",
  },
  {
    img: qrCodeImg,
    alt: "QR Code",
    description: "Imprima a carteirinha digital completa ou apenas o QR Code do seu pet para facilitar a identificação.",
  },
];

const styles = {
  dashboardHome: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
  },
  paragraph: {
    color: '#555',
    fontSize: '16px',
    lineHeight: '1.6',
  },
  infoSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  infoItem: {
    position: 'relative',
    flex: '1 1 100%', // No mobile, cada item ocupa 100% da largura
    margin: '10px 0', // Menos espaço entre os itens no mobile
    backgroundColor: '#fff',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
  },
  imageContainer: {
    position: 'relative',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  infoImage: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  descriptionOverlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
    backgroundColor: '#000',
    opacity: 0.8,
    padding: '15px',
    borderRadius: '8px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  descriptionText: {
    margin: '5px 0',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#fff',
  },
  // Responsividade
  '@media (max-width: 768px)': {
    infoItem: {
      flex: '1 1 100%', // No mobile, cada item ocupa 100% da largura
      margin: '10px 0', // Menos espaço entre os itens no mobile
    },
    descriptionText: {
      fontSize: '16px', // Ajuste do tamanho da fonte no mobile
    },
  },
  // Ajustes para desktop
  '@media (min-width: 1024px)': {
    infoSection: {
      justifyContent: 'space-between', // No desktop, mais espaçamento entre os itens
    },
    infoItem: {
      flex: '1 1 calc(33.33% - 20px)', // No desktop, os itens ocupam 33.33% da largura
      margin: '10px', // Margem entre os itens
    },
  },
};

export default DashboardHome;
