import React from 'react';

import { Container} from './components/MarketPlace.js';
import Endereco from './components/Endereco/index.js';
import Input from './components/Input/index.js';
import Cupom from './components/Cupom/index.js';
import Sugestao from './components/Sugestao/index.js';
import Destaque from './components/Destaque/index.js';
import Categoria from './components/Categoria/index.js';
import Lojas from './components/Lojas/index.js';

function MarketPlace() {
  return (
    <Container>
      <Endereco />
      <Input placeholder="Busque por item ou loja" />
      <Categoria />
      <Cupom />
      <Sugestao />
      <Destaque />
      
      <Lojas />
      
             <h1>asda</h1>
            
      
    </Container>
  );
}



export default MarketPlace;
